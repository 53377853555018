import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { BASE_URI } from '../../../shared/Constants';
import { DealerContext } from '../../../shared/DealerContext';

export default function ProductFilter(pageNumber, pageSize, order, orderBy, forceReload, searchString, makeId, filterBy) {
  const { selectedMotorGroup } = useContext(DealerContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [ranges, setRanges] = useState([]);
  const [total, setTotal] = useState(0);

  const dealerId = selectedMotorGroup?.id;

  useEffect(() => {
    setError(false);
    const params = {
      dealerId,
      pageNumber: pageNumber + 1,
      pageSize,
      type: filterBy,
      makeId: makeId || null,
      searchString: searchString?.trim()?.length > 0 ? searchString.trim() : null
    };
    if (order) {
      params.order = order.toUpperCase();
    }
    if (orderBy) {
      params.orderBy = orderBy;
    }
    if (dealerId !== undefined) {
      setLoading(true);
      if (filterBy === 'auto') {
        axios({
          method: 'GET',
          url: `${BASE_URI}/products`,
          params
        })
          .then((response) => {
            setRanges(response.data?.list);
            setTotal(response.data?.total);
          })
          .catch((error) => {
            if (axios.isCancel(error)) return;
            if (error.response) {
              setError(true);
            }
          })
          .then(() => {
            setLoading(false);
          });
      } else {
        axios({
          method: 'GET',
          url: `${BASE_URI}/nonautoproducts`,
          params
        })
          .then((response) => {
            setRanges(response.data?.list);
            setTotal(response.data?.total);
          })
          .catch((error) => {
            if (axios.isCancel(error)) return;
            if (error.response) {
              setError(true);
            }
          })
          .then(() => {
            setLoading(false);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, order, orderBy, dealerId, forceReload, searchString, makeId, filterBy]);

  return { loading, error, ranges, total };
}
