export const DEFAULT_PAGE_SIZE = 25;
export const IMAGE_UPLOADER_CONFIG = {
    maxWidth: 1920,
    maxHeight: 1080,
    compressFormat: 'JPEG',
    quality: 100,
    rotation: 0,
    outputType: 'base64'
};
export const FACEBOOK_BUSINESS_ID = '216155158722694';
export const FACEBOOK_APP_ID = '129374355593018';//'129374355593018';//'563793848142903';
export const FACEBOOK_GRAPH_BASE_URL = 'https://graph.facebook.com';
export const FACEBOOK_API_VERSON = 'v11.0';
export const DEFAULT_COLOR = '#333'
// export const BASE_URI = 'https://c2-marketdealer-leadcentre.azurewebsites.net/api'
export const BASE_URI = process.env.REACT_APP_CONEXA_API_URL;
export const SESSION_KEY = process.env.REACT_APP_SESSION_KEY;
// export const SESSION_KEY = '059B0434AC637273D3360674EC31A93E8783377453AE38E9632A3BDE8367B9AC';
// export const BASE_URI = 'https://localhost:5001/api'
export const BASE_URI_WEPAY4CARS = "https://c2-wepay4cars-marketdealer.azurewebsites.net/api";
export const DASH_URI = 'dash'
export const AUTH_USER = 'user';
export const AUTH_PERMISSION = 'permissions';
export const IS_AUTHENTICATED = 'isAuthenticated';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again later.'
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// eslint-disable-next-line no-useless-escape
export const WEBSITE_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
export const LOOKUP_URI = "applicationlookup";

export const LeadStatusEnum = {
    'New Lead': 1,
    'Prequalified Approved': 2,
    'Prequalified Declined': 3,
    'Application Completed': 4,
    'Application Submitted': 5,
    'Application Approved': 6,
    'Application Declined': 7,
    'Prequalified Submitted': 8,
    'Lost': 9,
    'Offer to Purchase': 10,
    'Documents In': 11,
    'Bank Submitted': 12,
    'Bank Validated': 13,
    'Bank Invalidated': 14,
    'Not Taken Up': 15,
    'Invoiced': 16,
    'Contracted': 17,
    'Delivered': 18,
    'Paid Out': 19,
    'Returned': 20,
    'Test Drive': 21
}
//user roles
export const ROLE_DEALER = 'Dealer';
export const ROLE_DEALER_ADMIN = 'DealerAdmin';
export const ROLE_ACCOUNT_MANAGER = 'AccountManager';
export const ROLE_CONTENT = 'Content';
export const ROLE_ADMIN = 'Admin';

//permissions
export const PERMISSION_DASHBOARD = 'dashboard';
export const PERMISSION_CONFIGURATOR = 'configurator';
export const PERMISSION_VEHICLE_LIBRARY = 'vehicle library';
export const PERMISSION_SLIDES = 'slides';
export const PERMISSION_OFFERS = 'offers';
export const PERMISSION_CATEGORIES = 'categories';
export const PERMISSION_BLOGS = 'blogs';
export const PERMISSION_PRODUCTS = 'products';
export const PERMISSION_MOTORGROUPS = 'motorgroups';
export const PERMISSION_WEBSITES = 'websites';
export const PERMISSION_TEMPLATES = 'templates';
export const PERMISSION_BUILDS = 'builds';
export const PERMISSION_DEALERS = 'dealers';
export const PERMISSION_USERS = 'users';
export const PERMISSION_ROLES = 'roles';
export const PERMISSION_DEALER_DEPARTMENTS = 'dealer departments';
export const PERMISSION_LEAD_STATES = 'lead states';
export const PERMISSION_BULK_ACTION = 'bulk action';
export const PERMISSION_MANUFACTURERS = 'manufacturers';
export const PERMISSION_MANAGE_VEHICLES = 'manage vehicles';
export const PERMISSION_TEMPLATE_FORMS = 'template forms';

export const BUILD_NUMBER = 'build_number';


export const LeadTypeEnum = {
    'New Cars': 1,
    'Used Cars': 2,
    'Dealer Contact': 3,
    'Staff Contact': 4,
    'Service Booking': 5,
    'Part': 6,
    'Personal Loan': 7,
    'Test Drive': 8,
    'TradeIn': 11,
    'FinanceApplication': 12,
    'Sell Car': 13,
    'Service Quote': 14,
    'Fleet': 15,
    'Corporate':16
}

Object.freeze(LeadStatusEnum)
