import { Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles/index';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import ConfirmationDialog from '../../../shared/ConfirmationDialog';
import { BASE_URI, DEFAULT_PAGE_SIZE } from '../../../shared/Constants';
import { DealerContext } from '../../../shared/DealerContext';
import ImageService from '../../../shared/ImageService';
import TableHeadersSort from '../../../shared/TableHeadersSort';
import { CurrencyConverter, CurrencyValue } from '../../../shared/TextMask';
import ProductFilter from './ProductFilter';
import ProductForm from './ProductForm';

export default function ProductListContainer() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column'
    }
  }));

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('title');
  const [tableView, setTableView] = useState(true);
  const [formView, setFormView] = useState(false);
  const [anchorRef, setAnchorRef] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeRange, setActiveRange] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { dealerId } = useContext(DealerContext);
  const [forceReload, setForceReload] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filterBy, setFilterBy] = useState('auto');
  const [makeId, setMakeId] = useState();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { loading, ranges, total } = ProductFilter(page, pageSize, order, orderBy, forceReload, searchString, makeId, filterBy);

  const handleAddModelClick = () => {
    if (!dealerId) {
      // Show snackbar if dealerId is not selected
      enqueueSnackbar('Please select a dealer first.', { variant: 'warning' });
    } else {
      // If dealerId is selected, navigate to the add Products page
      setTableView((prev) => !prev);
      setActiveRange(null);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setOpen(false);
    if (anchorRef) {
      setAnchorRef(null);
    }
  };

  const handleMenuToggle = (event, range) => {
    setActiveRange(range);
    setAnchorRef(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const headCells = [
    { id: 'title', numeric: false, label: 'Title', sort: true },
    { id: 'make', numeric: false, label: 'Make', sort: true },
    { id: 'model', numeric: false, label: 'Model', sort: true },
    { id: 'price', numeric: false, label: 'Price', sort: true },
    { id: 'actions', numeric: false, label: ' ', sort: false }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleDeleteRange = () => {
    setOpenConfirm(false);
    if (activeRange) {
      deleteRange(activeRange.id)
        .then((res) => {
          setForceReload((prev) => !prev);
          enqueueSnackbar('Successfully deleted vehicle model', { variant: 'success' });
        })
        .catch((err) => {
          enqueueSnackbar('Unable to delete vehicle model', { variant: 'error' });
        });
    }
  };

  const handleModelFormAction = (data) => {
    if (data && dealerId) {
      if (data?.id > 0) {
        editRange(data)
          .then((res) => {
            cancelForm();
            enqueueSnackbar('Successfully edited product', { variant: 'success' });
            setForceReload(true);
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar('Unable to edit vehicle model', { variant: 'error' });
          });
      } else {
        createProduct(data)
          .then((res) => {
            cancelForm();
            enqueueSnackbar('Successfully added vehicle model', { variant: 'success' });
            setForceReload(true);
          })
          .catch((err) => {
            enqueueSnackbar(err?.response?.data, { variant: 'error' });
          });
      }
    } else {
      enqueueSnackbar('Dealer ID is missing', { variant: 'error' });
    }
  };

  const handleInformationFormAction = async (values) => {
    let filePath1 = values.brochure1 && values.brochure1.path ? await getFilePath(values.brochure1) : null;
    let filePath2 = values.brochure2 && values.brochure2.path ? await getFilePath(values.brochure2) : null;

    const mainImg = values?.main?.imageUrl;
    let mainCI = !values?.main
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'main';
          }),
          imageName: 'main',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: mainImg?.image || mainImg,
            altTag: values.main.altTag || ''
          }
        };

    const mainMobileImg = values?.mainMobile?.imageUrl;
    let mainMobileCI = !values?.mainMobile
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'mainMobile';
          }),
          imageName: 'mainMobile',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: mainMobileImg?.image || mainMobileImg,
            altTag: values.mainMobile.altTag || ''
          }
        };

    const smallImage = values?.small?.imageUrl;
    let smallCI = !values?.small
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'small';
          }),
          imageName: 'small',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: smallImage?.image || smallImage,
            altTag: values.small.altTag || ''
          }
        };

    const mediumImg = values?.medium1?.imageUrl;
    let medium1CI = !values?.medium1
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'medium1';
          }),
          imageName: 'medium1',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: mediumImg?.image || mediumImg,
            altTag: values.medium1.altTag || ''
          }
        };

    const medium2Img = values?.medium2?.imageUrl;
    let medium2CI = !values?.medium2
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'medium2';
          }),
          imageName: 'medium2',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: medium2Img?.image || medium2Img,
            altTag: values.medium2.altTag || ''
          }
        };

    const medium3Img = values?.medium3?.imageUrl;
    let medium3CI = !values?.medium3
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'medium3';
          }),
          imageName: 'medium3',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: medium3Img?.image || medium3Img,
            altTag: values.medium3.altTag || ''
          }
        };

    const medium4Img = values?.medium4?.imageUrl;
    let medium4CI = !values?.medium4
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'medium4';
          }),
          imageName: 'medium4',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: medium4Img?.image || medium4Img,
            altTag: values.medium4.altTag || ''
          }
        };

    const medium5Img = values?.medium5?.imageUrl;
    let medium5CI = !values?.medium5
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'medium5';
          }),
          imageName: 'medium5',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: medium5Img?.image || medium5Img,
            altTag: values.medium5.altTag || ''
          }
        };

    const medium6Img = values?.medium6?.imageUrl;
    let medium6CI = !values?.medium6
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'medium6';
          }),
          imageName: 'medium6',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: medium6Img?.image || medium6Img,
            altTag: values.medium6.altTag || ''
          }
        };
    const medium7Img = values?.medium7?.imageUrl;
    let medium7CI = !values?.medium7
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'medium7';
          }),
          imageName: 'medium7',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: medium7Img?.image || medium7Img,
            altTag: values.medium7.altTag || ''
          }
        };
    const medium8Img = values?.medium8?.imageUrl;
    let medium8CI = !values?.medium8
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'medium8';
          }),
          imageName: 'medium8',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: medium8Img?.image || medium8Img,
            altTag: values.medium8.altTag || ''
          }
        };
    const medium9Img = values?.medium9?.imageUrl;
    let medium9CI = !values?.medium9
      ? null
      : {
          ...activeRange?.productInformation?.contentImages?.find((i) => {
            return i.imageName === 'medium9';
          }),
          imageName: 'medium9',
          productInformationId: activeRange?.productInformation?.id,
          image: {
            imageUrl: medium9Img?.image || medium9Img,
            altTag: values.medium9.altTag || ''
          }
        };

    let brochure1 = !values.brochure1
      ? null
      : {
          ...activeRange.productInformation?.brochures[0],
          productInformationId: activeRange?.productInformation?.id,
          document: {
            ...activeRange.productInformation?.brochures[0]?.document,
            fileName: values.brochure1?.name || activeRange.productInformation?.brochures[0]?.document?.fileName,
            filePath: filePath1 || activeRange.productInformation?.brochures[0]?.document?.filePath
          }
        };

    let brochure2 = !values.brochure2
      ? null
      : {
          ...activeRange.productInformation?.brochures[1],
          productInformationId: activeRange?.productInformation?.id,
          document: {
            ...activeRange.productInformation?.brochures[1]?.document,
            fileName: values.brochure2?.name || activeRange.productInformation?.brochures[1]?.document?.fileName,
            filePath: filePath2 || activeRange.productInformation?.brochures[1]?.document?.filePath
          }
        };

    for (let i = 0; i < values.galleryImages.length; i++) {
      const galleryImg = values.galleryImages[i]?.image.imageUrl;
      values.galleryImages[i].image.imageUrl = galleryImg?.image || galleryImg;
    }

    for (let i = 0; i < values.features.length; i++) {
      const featureImg = values.features[i]?.image.imageUrl;
      values.features[i].image.imageUrl = featureImg?.image || featureImg;
    }

    let productInfo = filterBy === 'auto' ? {
      ...activeRange.productInformation,
      ...values,
      deal: values?.deal ? { ...values?.deal, productInformationId: activeRange?.productInformation?.id } : null,
      productId: activeRange.id,
      videoLink: values.videoLink,
      contentImages: [
        mainCI,
        mainMobileCI,
        smallCI,
        medium1CI,
        medium2CI,
        medium3CI,
        medium4CI,
        medium5CI,
        medium6CI,
        medium7CI,
        medium8CI,
        medium9CI
      ].filter((ci) => ci != null),
      brochures: [brochure1, brochure2]
    }
    :
    {
      ...activeRange.nonAutoProductInformation,
      ...values,
      deal: values?.deal ? { ...values?.deal, nonAutoProductInformationId: activeRange?.nonAutoProductInformation?.id } : null,
      nonAutoProductId: activeRange.id,
      videoLink: values.videoLink,
      contentImages: [
        mainCI,
        mainMobileCI,
        smallCI,
        medium1CI,
        medium2CI,
        medium3CI,
        medium4CI,
        medium5CI,
        medium6CI,
        medium7CI,
        medium8CI,
        medium9CI
      ].filter((ci) => ci != null),
      brochures: [brochure1, brochure2]
    }
    
    ;

    if (productInfo && dealerId) {
      if (productInfo.id !== undefined) {
        editInformation(productInfo)
          .then((res) => {
            activeRange.productInformation = res.data;

            cancelForm();
            enqueueSnackbar('Successfully updated model information', { variant: 'success' });
            setForceReload(true);
          })
          .catch((err) => {
            enqueueSnackbar('Failed to edit model information: ' + err, { variant: 'error' });
          });
      } else {
        createInformation(productInfo)
          .then((res) => {
            activeRange.productInformation = res.data;

            cancelForm();
            enqueueSnackbar('Successfully added model information', { variant: 'success' });
            setForceReload(true);
          })
          .catch((err) => {
            enqueueSnackbar('Failed to load model information: ' + err, { variant: 'error' });
          });
      }
    } else {
      enqueueSnackbar('Dealer ID is missing', { variant: 'error' });
    }
  };

  const getFilePath = async (doc) => {
    var filePath = null;

    try {
      var base64Image = doc instanceof File ? await ImageService.getBase64(doc) : doc;

      filePath = base64Image;
    } catch (error) {
      enqueueSnackbar('Failed to get brochure file path. Brochure did not save: ' + error, { variant: 'error' });
    }

    return filePath;
  };

  const createInformation = (formData) => {
    let endpoint = filterBy ==='auto' ? 'ProductInformation': 'NonAutoProductInformation'
    return axios.post(`${BASE_URI}/${endpoint}`, formData);
  };

  const editInformation = (formData) => {
    let endpoint = filterBy ==='auto' ? 'ProductInformation': 'NonAutoProductInformation'
    return axios.put(`${BASE_URI}/${endpoint}`, formData);
  };

  const createProduct = (formData) => {
    let data = {
      image: formData.image.image,
      fileName: formData.image.fileName,
      dealerId: dealerId,
      modelId: formData.isComingSoon === true ? null : formData.modelId,
      makeId: formData.makeId,
      price: formData.isPOA === true || formData.isComingSoon === true ? 0 : CurrencyConverter(formData.price),
      title: formData.title,
      isComingSoon: formData.isComingSoon,
      categoryId: formData.categoryId,
      multiSelectCategories: formData.multiSelectCategories,
      isPOA: formData.isPOA,
      type: filterBy
    };

    let endpoint = filterBy ==='auto' ? 'products': 'nonautoproducts'

    return axios.post(`${BASE_URI}/${endpoint}`, data);
  };

  const editRange = (formData) => {
    const modelImage = formData.image;

    let data = {
      id: formData.id,
      image: modelImage?.image || modelImage,
      fileName: formData.image.fileName,
      dealerId: dealerId,
      modelId: formData.isComingSoon === true ? null : formData.modelId,
      makeId: formData.makeId,
      price: formData.isPOA === true || formData.isComingSoon === true ? 0 : CurrencyConverter(formData.price),
      title: formData.title,
      isComingSoon: formData.isComingSoon,
      altTag: formData.altTag,
      categoryId: formData.categoryId,
      multiSelectCategories: formData.multiSelectCategories,
      isPOA: formData.isPOA
    };
    let endpoint = filterBy ==='auto' ? 'products': 'nonautoproducts'
    return axios.put(`${BASE_URI}/${endpoint}/${data.id}`, data);
  };

  const deleteRange = (id) => {
    return axios.delete(`${BASE_URI}/products/${id}`);
  };

  const cancelForm = () => {
    setActiveRange(null);
    setFormView((prev) => !prev);
  };

  const handleSearchString = (val) => {
    setSearchString(val);
  };

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <ConfirmationDialog
          secondaryInfo={activeRange ? activeRange.title : ''}
          action='delete'
          open={openConfirm}
          confirm={handleDeleteRange}
          closeDialog={() => setOpenConfirm(false)}
        />
        <Zoom in={tableView} mountOnEnter unmountOnExit onExited={() => setFormView((prev) => !prev)}>
          <div>
            <Toolbar disableGutters>
              <Typography style={{ flex: '1 1' }} variant='h4' id='tableTitle' component='div'>
                Products
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Button
                  onClick={handleAddModelClick}
                  variant='contained'
                  style={{
                    marginBottom: '1rem', // Space between button and select
                    textTransform: 'none',
                    borderRadius: '5px',
                    border: '1px solid #012F56',
                    background: '#012F56'
                  }}
                  startIcon={<AddIcon />}
                  color='primary'
                >
                 { `Add ${filterBy} Product` }
                </Button>

                <Select
                  value={filterBy}
                  onChange={handleFilterChange}
                  displayEmpty
                  className={classes.select}
                  inputProps={{ 'aria-label': 'Filter by' }}
                >
                  <MenuItem value='auto'>Auto</MenuItem>
                  <MenuItem value='non-auto'>Non-Auto</MenuItem>
                </Select>
              </div>
            </Toolbar>
            <TextField
              variant='outlined'
              placeholder='Type Product Name or Make...'
              onChange={(e) => handleSearchString(e.target.value)}
              InputProps={{
                style: {
                  borderBottom: '1px solid',
                  borderRadius: '5px',
                  border: '1px solid #ABBDD3',
                  width: '20rem',
                  height: '3rem'
                }
              }}
            />

            <TableContainer>
              <Table aria-label='ranges table'>
                <TableHeadersSort order={order} orderBy={orderBy} headCells={headCells} onRequestSort={handleRequestSort} />
                <TableBody>
                  {ranges?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <List>
                          <ListItem alignItems='center' style={{ height: '48px' }}>
                            <ListItemAvatar>
                              <img altTag='' src={row.image} style={{ width: '75px', height: '64px' }} />
                            </ListItemAvatar>
                            <ListItemText style={{ marginLeft: '12px' }} primary={<p>{row.title}</p>} />
                          </ListItem>
                        </List>
                      </TableCell>
                      <TableCell>{row.make}</TableCell>
                      <TableCell>{row.model}</TableCell>
                      <TableCell>
                        <CurrencyValue value={row.price} />
                      </TableCell>
                      <TableCell className='pr-0' style={{ width: '5%' }}>
                        <IconButton aria-label='options' onClick={(e) => handleMenuToggle(e, row)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {(!ranges.length || ranges.length === 0) && !loading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }} className='py-3'>
                        <Typography variant='h6' color='textSecondary'>
                          {dealerId !== '' ? 'No Products Added For This Dealer' : 'Select a dealer!'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Popper open={open} anchorEl={anchorRef} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleMenuClose}>
                        <MenuList id='split-button-menu'>
                          <MenuItem
                            onClick={() => {
                              setTableView((prev) => !prev);
                              handleMenuClose();
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpenConfirm(true);
                              handleMenuClose();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <TablePagination
                component='div'
                count={total}
                page={page}
                rowsPerPageOptions={[5, 10, 25]}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </Zoom>
        <Zoom in={formView} onExited={() => setTableView((prev) => !prev)} mountOnEnter unmountOnExit>
          <div>
            <ProductForm
              data={activeRange}
              filterBy={filterBy}
              onSubmitProductForm={handleModelFormAction}
              onSubmitInformationForm={handleInformationFormAction}
              cancel={cancelForm}
            />
          </div>
        </Zoom>
      </Paper>
    </React.Fragment>
  );
}
