import React from "react";
import BlogForm from "./BlogForm";

export default function BlogContainer() {

    return (
        <>
             <BlogForm/>
        </>
    );
}
