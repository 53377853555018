import MomentUtils from '@date-io/moment';
import {
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URI, DEFAULT_PAGE_SIZE, LeadTypeEnum } from '../../shared/Constants';
import { LeadMainContext } from '../../shared/contexts/LeadMainContext';
import { DealerContext } from '../../shared/DealerContext';
import ExportContainer from '../../shared/ExportContainer';
import TableHeadersSort from '../../shared/TableHeadersSort';
import Leads from '../LeadsContainer/LeadsFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '45ch'
    }
  },
  export: {
    width: '45ch'
  },
  addLeadButton: {
    marginLeft: '60%',
    textDecoration: 'none'
  },
  stepPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  buttonFlagged: {
    backgroundColor: 'red'
  },
  buttonDisabled: {
    cursor: 'not-allowed'
  },
  buttonAllowed: {
    cursor: 'pointer',
    backgroundColor: 'light-green'
  }
}));

export default function WebsiteLeads() {
  const classes = useStyles();
  let { ...context } = useContext(LeadMainContext);
  const { isMasterDealer, selectedMotorGroup, dealerArr } = useContext(DealerContext);

  const [searchString, setSearchString] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('DateUpdated');

  const [selectedDate, setSelectedDate] = useState(context.dates);
  const [selectedToDate, setSelectedToDate] = useState(context.toDate);
  const [names, setNames] = useState('');
  const [source, setSource] = useState('');
  const [leadName, setLeadName] = useState('');
  const [leadTypes, setLeadTypes] = useState([]);
  const [services, setServices] = useState([]);
  const [crmSettings, setCrmSettings] = useState([]);
  const [selectedSettings, setSelectedSettings] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getLeadTypes();
    loadCrmSettings();
  }, []);

  const allLeadState = -1;

  let { loading, ranges, total } = Leads(
    page,
    pageSize,
    order,
    orderBy,
    allLeadState,
    selectedDate,
    selectedToDate,
    source,
    names,
    leadName,
    selectedMotorGroup.motorgroupID,
    id,
    searchString,
    false
  );

  const handleDateChange = (date) => {
    setSelectedDate(new Date(date));
  };

  const handleToDateChange = (date) => {
    setSelectedToDate(new Date(date));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPage(0);
    setSearchString(searchInput);
  };

  const headCellsWithMasterDealer = [
    { id: 'Id', numeric: false, label: 'Id', sort: true },
    { id: 'Name', numeric: false, label: 'Name', sort: true },
    { id: 'PhoneNumber', numeric: false, label: 'Phone', sort: true },
    { id: 'Email Address', numeric: false, label: 'EmailAddress', sort: true },
    { id: 'Type', numeric: false, label: 'Type', sort: true },
    { id: 'LeadOrigin', numeric: false, label: 'Lead Origin', sort: true },
    { id: 'SourceCode', numeric: false, label: 'Source Code', sort: true },
    { id: 'LeadSubmissionStatus', numeric: false, label: 'Submission Status', sort: true },
    { id: 'SubmissionError', numeric: false, label: 'Submission Error (if failed)', sort: true },
    { id: 'Dealer', numeric: false, label: 'Dealer', sort: true },
    { id: 'DateUpdated', numeric: false, label: 'Updated', sort: true }
  ];

  const headCellsWithoutMasterDealer = [
    { id: 'Id', numeric: false, label: 'Id', sort: true },
    { id: 'Name', numeric: false, label: 'Name', sort: true },
    { id: 'PhoneNumber', numeric: false, label: 'Phone', sort: true },
    { id: 'Email Address', numeric: false, label: 'EmailAddress', sort: true },
    { id: 'Type', numeric: false, label: 'Type', sort: true },
    { id: 'LeadOrigin', numeric: false, label: 'Lead Origin', sort: true },
    { id: 'SourceCode', numeric: false, label: 'Source Code', sort: true },
    { id: 'LeadSubmissionStatus', numeric: false, label: 'Submission Status', sort: true },
    { id: 'SubmissionError', numeric: false, label: 'Submission Error (if failed)', sort: true },
    { id: 'DateUpdated', numeric: false, label: 'Updated', sort: true }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const loadCrmSettings = async () => {
    try {
      const [crmSettings, activeServices] = await Promise.all([
        axios.get(`${BASE_URI}/Dealers/settings/${id}`).then((res) => res.data || []),
        axios.get(`${BASE_URI}/LeadServices/${id}`).then((res) => res.data || [])
      ]);

      setCrmSettings(crmSettings);

      if (!crmSettings.length || !activeServices.length) return;

      let matchingServices = new Set();

      crmSettings.forEach((d) => {
        Object.keys(d).forEach((k) => {
          if (activeServices.some((s) => k.toLowerCase().includes(s.name.replace(/\s/g, '').toLowerCase()))) {
            matchingServices.add(k);
          }
        });
      });

      setServices([...matchingServices]);
    } catch (error) {
      console.error('Error loading CRM settings:', error);
    }
  };

  function getLeadTypes() {
    const allLeadTypes = [...Object.entries(LeadTypeEnum).map(([key, value]) => ({ id: value, name: key }))];
    setLeadTypes(allLeadTypes);
  }

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }))(TableRow);

  const handleDealerService = (e) => {
    let selectedService = e.target.value;
    setSelectedService(selectedService);
    // Array to store the matching objects
    const matchingResults = [];

    // Loop through crmSettings array
    crmSettings.forEach((d) => {
      // Loop through the keys of each object and check if any key matches the selected service
      Object.keys(d).forEach((k) => {
        if (k.toLowerCase().includes(selectedService.toLowerCase())) {
          matchingResults.push({
            value: d[k],
            leadTypeId: d.leadTypeId
          });
        }
      });
    });

    setSelectedSettings(matchingResults);
  };

  let filterSearchLeads = ranges.filter(
    (a) =>
      searchString === '' ||
      a.phone?.toLowerCase().includes(searchString.toLowerCase()) ||
      a.id?.toString().includes(searchString.toLowerCase())
  );

  const getWebhookSource = (selectedSettings, row) => {
    switch (selectedService) {
      case 'customWebhookSettings':
        return selectedSettings
          .find((setting) => setting.leadTypeId === row.leadTypeId)
          ?.value?.find((s) => s.leadTypeId === row.leadTypeId && s.leadSourceId === row.leadSourceId)?.webhookSource;
      case 'dealersOnlineSettings':
        return selectedSettings
          .find((setting) => setting.leadTypeId === row.leadTypeId)
          ?.value?.find((s) => s.leadTypeId === row.leadTypeId && s.leadSourceId === row.leadSourceId)?.dealersOnlineSource;
      case 'autoHubSettings' || 'cmsSettings':
        return selectedSettings
          .find((setting) => setting.leadTypeId === row.leadTypeId)
          ?.value?.find((s) => s.leadTypeId === row.leadTypeId && s.leadSourceId === row.leadSourceId)?.source;

      default:
        return row.customSourceCode;
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid container spacing={2}>
          {/* DatePicker */}
          <Grid item xs={12} sm={4} md={4}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <DatePicker
                label='From'
                clearable
                fullWidth
                disableFuture
                value={selectedDate}
                onChange={handleDateChange}
                animateYearScrolling
                style={{width:'fit-content', marginRight:'20px'}}
              />
              <DatePicker
                label='To'
                clearable
                fullWidth
                disableFuture
                value={selectedToDate}
                onChange={handleToDateChange}
                animateYearScrolling
                style={{width:'fit-content'}}
              />
            </MuiPickersUtilsProvider>
          </Grid>
 
          {/* Search Input */}
          <Grid item xs={12} sm={4} md={4}>
            <form onSubmit={handleSubmit}>
              <TextField
                name='leadSearch'
                id='leadSearch'
                label='Search Leads'
                value={searchInput}
                type='text'
                fullWidth
                InputProps={{
                  endAdornment: searchInput ? (
                    <InputAdornment position='end'>
                      <IconButton size='small' disableFocusRipple disableRipple onClick={() => setSearchInput('')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    ''
                  )
                }}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </form>
          </Grid>
                {!isMasterDealer &&
                  <Grid item xs={12} sm={4} md={4}>
                  <TextField fullWidth id='dealerService' select label='Dealer Service' onChange={(e) => handleDealerService(e)}>
                    {services.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  </Grid>
                }
          
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <ExportContainer
            values={ranges}
            fileName='LeadsList'
            headers={isMasterDealer ? headCellsWithMasterDealer : headCellsWithoutMasterDealer}
          />
        </Grid>
      </Grid>

      <Divider variant='fullWidth' style={{ marginTop: '1rem', marginBottom: '1rem' }} />

      <Grid container className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label='Leads Table'>
              <TableHeadersSort
                order={order}
                orderBy={orderBy}
                headCells={isMasterDealer ? headCellsWithMasterDealer : headCellsWithoutMasterDealer}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filterSearchLeads.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.id}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.phone}</StyledTableCell>
                    <StyledTableCell>{row.emailAddress}</StyledTableCell>
                    <StyledTableCell>
                      {leadTypes.find((l) => l.id == row.leadTypeId).name}
                      {row?.leadTypeName}
                    </StyledTableCell>
                    <StyledTableCell>{row.source}</StyledTableCell>
                    <StyledTableCell>{getWebhookSource(selectedSettings, row)}</StyledTableCell>
                    <StyledTableCell>{row.statusInformation}</StyledTableCell>
                    <StyledTableCell>{row.integrationErrorMessage}</StyledTableCell>
                    {isMasterDealer && <StyledTableCell>{dealerArr.find((d) => d.id == row.dealerId).name}</StyledTableCell>}
                    <StyledTableCell>{row.dateUpdated}</StyledTableCell>
                  </StyledTableRow>
                ))}
                {(!ranges.length || ranges.length === 0) && !loading && (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={isMasterDealer ? headCellsWithMasterDealer.length : headCellsWithoutMasterDealer.length}
                      align='center'
                      style={{ borderBottom: 0 }}
                      className='py-3'
                    >
                      <Typography variant='h6' color='textSecondary'>
                        No Leads
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {loading && (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={isMasterDealer ? headCellsWithMasterDealer.length : headCellsWithoutMasterDealer.length}
                      align='center'
                      style={{ borderBottom: 0 }}
                    >
                      <CircularProgress />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component='div'
              count={total}
              page={page}
              rowsPerPageOptions={[5, 10, 25]}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
