import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../shared/Constants';
import { DealerContext } from '../../shared/DealerContext';
import TableHeadersSort from '../../shared/TableHeadersSort';
import BlogFilter from './BlogFilter';
import { Box } from '@material-ui/core';
import { ImageContainer } from '../../shared/ImageContainer';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  removeblogbtn: {
    background: '#3f51b5',
    color: 'white',
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  select: {
    marginRight: theme.spacing(2),
  },
}));

export default function BlogTable() {
  const classes = useStyles();
  let history = useHistory();

  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('headline');
  const [searchString, setSearchString] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [blogStatus, setBlogStatus] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const { dealerId } = useContext(DealerContext);
  const { loading, blogs, total } = BlogFilter(searchString, blogStatus, page, pageSize, order, orderBy);

  const [headCells, setHeadCells] = useState([
    { id: '', label: '', sort: true },
    { id: 'headline', label: 'Headline', sort: true },
    { id: 'date', label: 'Publish Date', sort: true },
    { id: 'active', label: 'Active', sort: true },
  ]);

  useEffect(() => {
    if (!loading) {
      setFilteredBlogs(blogs || []);
      setIsLoading(false);
    }
  }, [blogs, loading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPage(0);
    setSearchString(searchInput);
  };

  // Apply the search string and filter type
  const filterSearchBlog = filteredBlogs
  .filter(blog =>
    // Match the blog name with the search string (case-insensitive)
    searchString === '' || blog.name.toLowerCase().includes(searchString.toLowerCase().trim())
  );

  const handleButtonClick = (id) => {
    history.push(`/blogs/edit/${id}`);
  };

  const handleAddBlogClick = () => {
    if (!dealerId) {
      // Show snackbar if dealerId is not selected
      enqueueSnackbar('Please select a dealer first.', { variant: 'warning' });
    } else {
      // If dealerId is selected, navigate to the add offers page
      history.push('/blogs/add');
    }
  };

  const filterArr = [
    'All',
    'Active',
    'Inactive'
  ]

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4} className={classes.stepPadding} direction='column'>
          <Grid item xs={12}>
            <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
              <Typography style={{ flex: '1 1' }} variant='h4' id='tableTitle' component='div'>
                Blogs
              </Typography>
              <TextField
                style={{width: '150px', marginRight: '2rem'}}
                name="statusFilter"
                id="statusFilter"
                label="Status"
                select
                defaultValue={"All"}
                value={blogStatus}
                onChange={(e) => setBlogStatus(e.target.value)}
              >
                {filterArr.map((option, index) => (
                  <MenuItem key={index} id={`${option}-${index}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <form onSubmit={handleSubmit}>
                {/* <TextField
                  name='blogFilter'
                  id='blogFilter'
                  label='Search Blogs'
                  value={searchInput}
                  type='text'
                  InputProps={{
                    endAdornment: searchInput ? (
                      <InputAdornment position='end'>
                        <IconButton size='small' disableFocusRipple disableRipple onClick={() => setSearchInput('')}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }}
                  onChange={(e) => setSearchInput(e.target.value)}
                /> */}
              </form>
              <Button
                onClick={handleAddBlogClick}
                variant='contained'
                style={{ marginLeft: '2rem' }}
                startIcon={<AddIcon />}
                color='primary'
              >
                Blog
              </Button>
            </Toolbar>

            <TableContainer>
              <Table aria-label='blogs table' size='small'>
                <TableHeadersSort order={order} orderBy={orderBy} headCells={headCells} onRequestSort={handleRequestSort} />
                <TableBody>
                  {filterSearchBlog.map((row) => (
                    <Tooltip title={`Edit ${row.name}`} key={row.id} placement='left'>
                      <TableRow hover className={classes.hoverTableCell}>
                      <TableCell size="small" style={{width:'250px'}}>
                            <div>
                              <Box boxShadow={3} mb={2}>
                                <ImageContainer
                                  src={row.banner.imageUrl}
                              />
                              </Box>
                            </div>
                        </TableCell>
                        <TableCell onClick={() => handleButtonClick(row.id)}>
                          <Typography style={{ color: row.status === 'inActive' ? '#ff5722' : '' }} variant='subtitle2'>
                          {row.headline
                          .split(' ')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                          .join(' ')}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={() => handleButtonClick(row.id)}>
                          {row.date ? row.date.split('T')[0] : new Date().toISOString().split('T')[0]}
                        </TableCell>
                        <TableCell onClick={() => handleButtonClick(row.id)}>
                          {row.active ? 'Yes' : 'No'}
                        </TableCell>

                      </TableRow>
                    </Tooltip>
                  ))}
                  {!filteredBlogs.length && !isLoading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }} className='py-3'>
                        <Typography variant='h6' color='textSecondary'>
                          {dealerId ? 'No Blogs Added' : 'Select a dealer!'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                count={total}
                page={page}
                rowsPerPage={pageSize}
                component='div'
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
