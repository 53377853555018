import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Toolbar
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-material-ui';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { BASE_URI, DEFAULT_PAGE_SIZE, LeadTypeEnum } from '../../shared/Constants';
import { LoadingContext } from '../../shared/context/loadingContext';
import { DealerContext } from '../../shared/DealerContext';
import ImageUploadInput from '../../shared/ImageUploadFormInput';
import { CurrencyConverter, CurrencyInput } from '../../shared/TextMask';
import CategoryFilter from '../categories/CategoryFilter';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from 'formik-material-ui-pickers';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  tr: {
    padding: '5px 30px'
  },
  th: {
    padding: '5px 10px',
    textAlign: 'center'
  },
  td: {
    padding: '2px 10px'
  },
  tdCenter: {
    padding: '2px 10px',
    textAlign: 'center'
  }
}));


export default function OfferForm({ history, match }) {
  const classes = useStyles();
  const { id } = match.params;
  const [offerId, setOfferId] = useState(id);
  const [isAddMode, setIsAddMode] = useState(!id);
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);
  const [leadTypes, setLeadTypes] = useState([]);
  const { dealerId, selectedMotorGroup: selectedDealer } = useContext(DealerContext);
  const [motorGroupDealers, setMotorGroupDealers] = useState([]);
  const [relatedDealerIDs, setRelatedDealerIDs] = useState([]);
  const [relatedDealers, setRelatedDealers] = useState([]);
  const [isLoadingDealers, setIsLoadingDealers] = useState(true);

  const initialValues = {
    headline: '',
    make: '',
    makeId: '',
    model: '',
    modelId: '',
    variant: '',
    variantId: '',
    // usedModel: '',
    // usedModelId: '',
    active: false,
    price: 0,
    rate: 0,
    fixedRate: false,
    deposit: 0,
    depositCash: false,
    balloonPayment: 0,
    balloonPaymentCash: false,
    monthlyInstallment: 0,
    term: 0,
    guaranteedValue: '', // ASK
    assistance: '', // ASK
    category: '', // ASK
    categoryId: 0,
    totalCost: 0,
    dateFrom: new Date(),
    dateTo: new Date(),
    crmOverride: '', // ASK
    campaignCode: '', // ASK
    leadType: '',
    leadTypeId: 0,
    termsAndConditions: '',
    metaDescription: '', //ASK ATTIE
    metaKeywords: '', //ASK ATTIE
    introParagraph: '',
    image: '',
    mobileImageUrl: '',
    isAddMode: isAddMode,
    redirectUrl: '',
    altTag: '',
    mobileAltTag: '',
    type: '',
    cta: '',
    imageRedirect: ''
  };
  const [formFields, setFormFields] = useState(initialValues);
  const [makeArr, setMakeArr] = useState([]);
  const [variantArr, setVariantArr] = useState([]);
  const [modelArr, setModelArr] = useState([]);
  const [disableModel, setDisabledModel] = useState(true);
  const [disableVariant, setDisabledVariant] = useState(true);
  const [isCashDeal, setIsCashDeal] = useState(false);
  const [modelArrId, setModelArrId] = useState([]);

  let modelArrIdCount = modelArrId.length;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');
  const [searchString, setSearchString] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const { categories, total } = CategoryFilter(searchString, page, pageSize, order, orderBy, true);

  let offerCats = categories.filter((cat) => cat.type.toLowerCase() === 'offer' && cat.dealerId === dealerId);

  useEffect(() => {
    const getOffer = async () => {
      try {
        showLoading();
        const result = await axios.get(`${BASE_URI}/Offers/${id}`);
        let formData = result.data;
        formData.isAddMode = isAddMode;
        getModels(formData.makeId);
        getVariants(formData.modelId);
        setDisabledVariant(false);
        setDisabledModel(false);
        setFormFields({
          ...formData
        });
        setIsCashDeal(formData.isCashDeal);
        setRelatedDealerIDs(result.data?.relatedDealerIDs);
        getOfferModel(id, formData.makeId, formData.modelId);
      } catch (error) {
        enqueueSnackbar('Unable to get offer details', { variant: 'error' });
      }
    };

    const getMakeArr = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/makes`);
        setMakeArr(result.data.list);
      } catch (error) {
        if (axios.isCancel(error)) return;
        enqueueSnackbar('Unable to get vehicle makes', { variant: 'error' });
      }
    };

    getMakeArr();
    getLeadTypes();

    if (!isAddMode) {
      getOffer().then(() => {
        hideLoading();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dealerId > 0 && selectedDealer?.motorgroupID >= 0) {
      getMotorGroupDealers();
    }
  }, [dealerId, selectedDealer]);

  useEffect(() => {
    syncRelatedDealers(motorGroupDealers, relatedDealerIDs);
  }, [motorGroupDealers, relatedDealerIDs]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };

  async function getMotorGroupDealers() {
    if (!selectedDealer?.motorgroupID) {
      setIsLoadingDealers(false);
      return motorGroupDealers;
    }

    const params = {
      motorgroupId: selectedDealer.motorgroupID,
      pageSize: 500 // Fetch all dealers as there's no paging management on the UI
    };

    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URI}/Dealers`,
        params,
        cancelToken: axios.CancelToken.source().token
      });

      let dealers = response?.data?.list?.filter((d) => d.id !== dealerId);

      if (!dealers || dealers.length <= 0) {
        enqueueSnackbar('No motorgroup dealers available for the current dealer', { variant: 'warning' });
        setIsLoadingDealers(false);
        return;
      }

      setMotorGroupDealers(dealers);
      return dealers;
    } catch (error) {
      if (!axios.isCancel(error)) {
        enqueueSnackbar(getErrorMessage(error, "Unable to load the motorgroup's dealers"), { variant: 'error' });
      }
      return [];
    } finally {
      setIsLoadingDealers(false);
    }
  }

  function syncRelatedDealers(dealers, dealerIDs) {
    let tempRelatedDealers = [];

    for (let d = 0; d < dealers?.length; d++) {
      const dealer = dealers[d];

      if (dealer.id == dealerId) continue;

      if (dealerIDs?.some((id) => id == dealer.id)) {
        // This dealer corresponds to a related dealer, tick it
        tempRelatedDealers.push({
          dealerId: dealer.id,
          name: dealer.name.trim(),
          isTicked: true
        });
      } else {
        // This dealer does not correspond to a related dealer, untick it
        tempRelatedDealers.push({
          dealerId: dealer.id,
          name: dealer.name.trim(),
          isTicked: false
        });
      }
    }

    setRelatedDealers([...tempRelatedDealers]);
  }

  const getOfferModel = async (id, makeId, modelId) => {
    try {
      // Fetch result asynchronously
      setModelArrId([modelId]);
      const result = await axios.get(`${BASE_URI}/models/offer/${id}`);
      if (result.data.length !== 0) {
        const modelIds = result.data.filter((model) => model.makeID === makeId).map((model) => model.id);
        setModelArrId(modelIds);
      }
    } catch (error) {
      enqueueSnackbar(`Unable to retrieve vehicle makes`, { variant: 'error' });
    }
  };

  function getErrorMessage(axiosError, defaultMsg) {
    let errorTitle = defaultMsg;
    let errorDetails = 'No additional details are available';

    if (axiosError?.request?.message || axiosError?.request?.statusText) {
      errorTitle = 'Failed to send the request';
      errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;
    } else if (axiosError?.response?.data) {
      errorTitle = axiosError?.response?.data?.title ?? errorTitle;
      errorDetails = axiosError?.response?.data?.details ?? errorDetails;
    }

    return `${errorTitle}: ${errorDetails}`;
  }

  function addOneDayToDates(fields, dateToKey = 'dateTo', dateFromKey = 'dateFrom') {
    // Add one day to the dateTo field
    if (fields[dateToKey]) {
      const newDateTo = new Date(fields[dateToKey]);
      newDateTo.setDate(newDateTo.getDate() + 1);
      fields[dateToKey] = newDateTo;
    }

    // Add one day to the dateFrom field
    if (fields[dateFromKey]) {
      const newDateFrom = new Date(fields[dateFromKey]);
      newDateFrom.setDate(newDateFrom.getDate() + 1); // Add one day to the new dateFrom
      fields[dateFromKey] = newDateFrom;
    }
  }

  const editOffer = async (id, fields, setSubmitting, resetForm) => {
    try {
      addOneDayToDates(fields);
      const result = await axios.put(`${BASE_URI}/Offers/${id}`, fields);
      enqueueSnackbar('Successfully saved the offer. Updating linked dealers', { variant: 'success' });
      saveToRelatedDealers(result?.data, setSubmitting, resetForm);
      history.push('/offers');
      return result;
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'Failed to save the offer'), { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  const createOffer = async (fields, setSubmitting, resetForm) => {
    try {
      addOneDayToDates(fields);
      fields.dealerId = dealerId;
      const result = await axios.post(` ${BASE_URI}/Offers`, fields, {
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*'
        }
      });

      enqueueSnackbar('Successfully saved the offer. Updating linked dealers', { variant: 'success' });
      saveToRelatedDealers(result?.data, setSubmitting, resetForm);

      setOfferId(result?.data?.id);
      setIsAddMode(false);
      history.push('/offers');
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'Failed to save the offer'), { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  async function saveToRelatedDealers(offer, setSubmitting, resetForm) {
    try {
      offer.relatedDealerIDs = relatedDealerIDs;
      const result = await axios.post(`${BASE_URI}/Offers/UpdateRelatedDealers/${offer.id}`, offer);
      enqueueSnackbar('Offer saved to linked dealers', { variant: 'success' });
      resetForm();
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'Failed to save the offer to the linked dealers'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  }

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    const offerImage = fields.image;
    const mobileImage = fields.mobileImageUrl;

    let tmpFields = {
      ...fields,
      makeId: parseInt(fields.makeId, 10),
      leadTypeId: parseInt(fields.leadTypeId, 10),
      totalCost: CurrencyConverter(fields.totalCost),
      price: CurrencyConverter(fields.price),
      monthlyInstallment: CurrencyConverter(fields.monthlyInstallment),
      variant: modelArrIdCount > 1 ? '' : fields.variant,
      model: modelArrIdCount > 1 ? '' : fields.model,
      modelId: modelArrIdCount > 1 ? 0 : fields.modelId,
      categoryId: fields.categoryId,
      variantId: modelArrIdCount > 1 ? 0 : parseInt(fields.variantId, 10),
      isCashDeal: isCashDeal,
      image: offerImage?.image || offerImage,
      mobileImageUrl: mobileImage?.image || mobileImage,
      fileName: fields.image.fileName,
      type: fields.type,
      multiSelectModels: modelArrIdCount > 1 ? modelArrId : []
    };

    setStatus();
    showLoading();

    if (isAddMode) {
      createOffer(tmpFields, setSubmitting, resetForm);
    } else {
      editOffer(id, tmpFields, setSubmitting, resetForm);
    }
  }

  function getLeadTypes() {
      const allLeadTypes = [...Object.entries(LeadTypeEnum).map(([key, value]) => ({ id: value, name: key }))];
      setLeadTypes(allLeadTypes);
    }

  const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
    setFieldValue(fieldName, e.target.value);
    setFieldValue(fieldId, value.props.id);
  };

  const handleLeadTypeChange = (e, value, setFieldValue) => {
    handleSelectChange(e, value, setFieldValue, 'leadType', 'leadTypeId');
  };

  const handleMakeChange = async (e, value, setFieldValue) => {
    setFieldValue('make', e.target.value);
    setFieldValue('makeId', value.props.id);

    //clear model
    setFieldValue('model', '');
    setFieldValue('modelId', 0);
    setModelArrId([]);
    setModelArr([]);
    setVariantArr([]); //reset variants

    setFieldValue('variant', '');
    setFieldValue('variantId', 0);

    try {
      const result = await axios.get(`${BASE_URI}/models`, {
        params: {
          makeId: value.props.id
        }
      });
      setDisabledModel(false);
      setModelArr(result.data.list);
    } catch (error) {
      setDisabledModel(true);
      enqueueSnackbar(`Unable to retrieve ${e.target.value} models`, { variant: 'error' });
    }
  };

  const handleModelChange = async (e, value, setFieldValue) => {
    setModelArrId(e.target.value);
    let modelId = e.target.value;
    let findModel = modelArr.find((mo) => mo.id === modelId[0]);
    setFieldValue('model', findModel?.title);
    setFieldValue('modelId', findModel?.id);
    try {
      if (modelArrIdCount <= 1) {
        const result = await axios.get(`${BASE_URI}/variants`, {
          params: {
            modelId: findModel.id
          }
        });
        setVariantArr(result.data.list);
        setDisabledVariant(false);
      }
    } catch (error) {
      setDisabledVariant(true);
      enqueueSnackbar('Unable to load vehicle variants', { variant: 'error' });
    }
  };

  const getModels = async (makeId) => {
    try {
      const result = await axios.get(`${BASE_URI}/models`, {
        params: {
          makeId
        }
      });
      setDisabledModel(false);
      setModelArr(result.data.list);
    } catch (error) {
      enqueueSnackbar('Unable to load vehicle variants', { variant: 'error' });
    }
  };

  const getVariants = async (modelId) => {
    try {
      if (modelArrIdCount <= 1) {
        const result = await axios.get(`${BASE_URI}/variants`, {
          params: {
            modelId: modelId
          }
        });
        setVariantArr(result.data.list);
        setDisabledVariant(false);
      }
    } catch (error) {
      enqueueSnackbar('Unable to load vehicle variants', { variant: 'error' });
    }
  };

  function toggleRelatedDealer(dealerId) {
    // This round-about way is to ensure that the checkbox is rendered after its value is updated
    let list = [...relatedDealers];
    let dealer = list.find((d) => d.dealerId == dealerId);
    dealer.isTicked = !dealer.isTicked;
    setRelatedDealers(list);
    setRelatedDealerIDs(list.filter((d) => d.isTicked).map((d) => d.dealerId));
  }

  return (
    <Paper className={classes.paper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={4} className={classes.stepPadding} direction='column'>
          <Grid item xs={12}>
            <Formik
              initialValues={formFields}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                headline: Yup.string().required('Headline is required'),
                make: Yup.string().required('Make is required'),
                price: Yup.string().required('Price is required'),
                leadType: Yup.string().required('Lead Type is required'),
                image: Yup.string().required('Offer Image is required'),
                model: modelArrIdCount <= 0 ? Yup.array().required('Please select at least one model') : null,
                variant: modelArrIdCount <= 1 ? Yup.string().required('Variant is required') : null,
                cta: Yup.string().required('CTA Text is required').max(100, 'CTA Text cannot be more than 100 characters')
              })}
            >
              {(props) => {
                const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;
                let categoryName = categories.find((cat) => cat.id == values.categoryId && offerId == values.id)?.name;
                // showErrors(errors)
                return (
                  <Form noValidate>
                    <Toolbar disableGutters>
                      <Typography variant='h6' style={{ flex: '1 1' }} component='div' gutterBottom>
                        {isAddMode ? 'Add' : 'Edit'} {values.headline ? values.headline : 'Offer'}
                      </Typography>
                      <FormControlLabel
                        control={<Field component={Switch} color='primary' type='checkbox' name='active' />}
                        label='Active'
                      />
                    </Toolbar>
                    <Grid spacing={4} container direction='row'>
                      <Grid item spacing={4} container xs={12}>
                        <Grid item md={6} xs={12}>
                          {/* Offer View Heading */}
                          <Box mb={3}>
                            <Typography variant='h6' gutterBottom>
                              Offer View
                            </Typography>
                          </Box>

                          {/* Headline Input */}
                          <Box mb={3}>
                            <TextField
                              fullWidth
                              name='headline'
                              id='headline'
                              required
                              label='Headline'
                              value={values.headline}
                              type='text'
                              helperText={errors.headline && touched.headline ? errors.headline : ''}
                              error={errors.headline && touched.headline}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant='outlined'
                            />
                          </Box>

                          {/* Price Input */}
                          <Box mb={3}>
                            <TextField
                              fullWidth
                              required
                              id='price'
                              label='Price'
                              value={values.price}
                              onChange={handleChange}
                              placeholder='e.g. 200,000'
                              error={errors.price && touched.price}
                              helperText={errors.price && touched.price ? 'Please enter a valid amount' : ''}
                              InputProps={{
                                inputComponent: CurrencyInput
                              }}
                              variant='outlined'
                            />
                          </Box>
                          {/* CTA Text Field */}
                          <Box mb={3}>
                            <TextField
                              fullWidth
                              name='cta'
                              id='cta'
                              label='CTA Text'
                              value={values.cta}
                              helperText={errors.cta && touched.cta ? errors.cta : ''}
                              error={errors.cta && touched.cta}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant='outlined'
                            />
                          </Box>

                          {/* Intro Paragraph - ReactQuill Editor */}
                          <Box mb={3}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography variant='h6' gutterBottom>
                                Description
                              </Typography>

                              <FormControlLabel
                                control={<Field component={Switch} color='primary' type='checkbox' name='plainField' id='plainField' />}
                                label='Plain Text'
                              />
                            </div>

                            <Box
                              sx={{
                                '& .ql-container': {
                                  height: '250px',
                                  overflow: 'auto'
                                }
                              }}
                            >
                              {values.plainField ? (
                                <TextField
                                  fullWidth
                                  name='introParagraph'
                                  id='introParagraph'
                                  required
                                  variant='outlined'
                                  multiline
                                  rows={15}
                                  label='Intro/Standout Paragraph'
                                  value={values.introParagraph}
                                  helperText={errors.introParagraph && touched.introParagraph ? errors.introParagraph : ''}
                                  error={errors.introParagraph && touched.introParagraph}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              ) : (
                                <ReactQuill
                                  value={values.introParagraph}
                                  onChange={(value) => setFieldValue('introParagraph', value)}
                                  modules={{
                                    toolbar: [
                                      [{ font: [] }], // Font family
                                      [{ size: ['small', false, 'large', 'huge'] }], // Font size
                                      ['bold', 'italic', 'underline'], // Text styling
                                      [{ color: [] }, { background: [] }], // Font color and background color
                                      [{ list: 'ordered' }, { list: 'bullet' }], // Lists
                                      ['blockquote'], // Blockquote
                                      ['link'], // Links
                                      ['clean'] // Clear formatting
                                    ]
                                  }}
                                  formats={[
                                    'font', // Font family
                                    'size', // Font size
                                    'bold',
                                    'italic',
                                    'underline', // Text styling formats
                                    'color', // Font color
                                    'background', // Background color
                                    'list',
                                    'bullet', // List formats
                                    'blockquote', // Blockquote
                                    'link', // Links
                                    'clean' // Clear formatting
                                  ]}
                                  placeholder='Write your content here...'
                                />
                              )}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ImageUploadInput title='Offer Image' required fieldName={'image'} error={errors.image && touched.image} />
                          <TextField
                            fullWidth
                            name='altTag'
                            id='altTag'
                            label='Alt Tag'
                            value={values.altTag}
                            type='text'
                            helperText={errors.altTag && touched.altTag ? errors.altTag : 'Alt tag for SEO'}
                            error={errors.altTag && touched.altTag}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <TextField
                            fullWidth
                            name='imageRedirect'
                            id='imageRedirect'
                            label='Image Redirect'
                            value={values.imageRedirect}
                            type='text'
                            helperText={errors.imageRedirect && touched.imageRedirect ? errors.imageRedirect : 'Image redirect URL'}
                            error={errors.imageRedirect && touched.imageRedirect}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ImageUploadInput
                            title='Mobile Image'
                            required
                            fieldName={'mobileImageUrl'}
                            error={errors.mobileImageUrl && touched.mobileImageUrl}
                          />
                          <TextField
                            fullWidth
                            name='mobileAltTag'
                            id='mobileAltTag'
                            label='Alt Tag'
                            value={values.mobileAltTag}
                            type='text'
                            helperText={errors.mobileAltTag && touched.mobileAltTag ? errors.mobileAltTag : 'Alt tag for SEO'}
                            error={errors.mobileAltTag && touched.mobileAltTag}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider variant='fullWidth' />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant='h6' style={{ flex: '1 1' }} component='div' gutterBottom>
                          Configuration
                        </Typography>
                      </Grid>

                      <Grid item spacing={4} container xs={12}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            id='leadType'
                            select
                            label='Lead Type'
                            value={values.leadType}
                            helperText={errors.leadType && touched.leadType ? errors.leadType : ''}
                            error={errors.leadType && touched.leadType}
                            onBlur={handleBlur('leadType')}
                            onChange={(e, child) => handleLeadTypeChange(e, child, setFieldValue)}
                            required
                          >
                            {leadTypes.map((option) => (
                              <MenuItem key={option.id} id={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          <TextField
                            fullWidth
                            id='make'
                            select
                            required
                            label='Make'
                            value={values.make}
                            helperText={errors.make && touched.make ? errors.make : ''}
                            error={errors.make && touched.make}
                            onBlur={handleBlur('make')}
                            onChange={(e, child) => handleMakeChange(e, child, setFieldValue)}
                          >
                            {makeArr.map((option) => (
                              <MenuItem key={option.id} id={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          <Select
                            labelId='model'
                            id='model'
                            multiple
                            fullWidth
                            value={modelArrId}
                            required
                            onChange={(e, child) => handleModelChange(e, child, setFieldValue)}
                            helperText={errors.model && touched.model ? errors.model : ''}
                            error={!!(errors.model && touched.model)}
                            input={<OutlinedInput id='model' label='Models' />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected !== null &&
                                  Array.isArray(selected) &&
                                  selected.map((value) =>
                                    value != 0 ? <Chip key={value} label={modelArr.find((mo) => mo.id === value)?.model} /> : <></>
                                  )}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {modelArr.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                <Checkbox checked={modelArrId.indexOf(option.id) > -1 || option.id === values.modelId} />
                                <ListItemText primary={option.title} />
                              </MenuItem>
                            ))}
                          </Select>

                          <br></br>
                          <TextField
                            fullWidth
                            id='variant'
                            select
                            label='New Variant'
                            required
                            value={modelArrIdCount <= 1 ? values.variant : ''}
                            disabled={disableVariant || modelArrIdCount > 1}
                            helperText={errors.variant && touched.variant ? errors.variant : ''}
                            error={errors.variant && touched.variant}
                            onBlur={handleBlur('variant')}
                            onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'variant', 'variantId')}
                          >
                            {variantArr.map((option) => (
                              <MenuItem key={option.id} id={option.id} value={option.variantName}>
                                {option.variantName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} md={6}>
                        <Tooltip title="Please make sure offer categories are added for this dealer">
                            <TextField
                              fullWidth
                              id="category"
                              select
                              label="Category"
                              value={values.category ?? categoryName}
                              helperText={errors.category && touched.category ? errors.category : ''}
                              error={errors.category && touched.category}
                              onBlur={handleBlur('category')}
                              onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'category', 'categoryId')}
                            >
                              {offerCats.map((option) => (
                                <MenuItem key={option.id} id={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Tooltip>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider variant='fullWidth' />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant='h6' style={{ flex: '1 1' }} component='div' gutterBottom>
                          Finance
                        </Typography>
                      </Grid>

                      <Grid item spacing={4} container xs={12}>
                        <Grid item md={6} xs={12}>
                          <FormControlLabel
                            control={
                              <Field
                                component={Switch}
                                color='primary'
                                type='checkbox'
                                name='isCashDeal'
                                onClick={(e) => {
                                  setIsCashDeal(!isCashDeal);
                                }}
                              />
                            }
                            label='Is Cash Deal?'
                          />
                        </Grid>
                      </Grid>

                      {!isCashDeal && (
                        <>
                          <Grid item xs={12} md={4}>
                            <Box display='flex' justifyContent='space-between'>
                              <TextField
                                name='rate'
                                required
                                id='rate'
                                label='Rate'
                                value={values.rate}
                                type='number'
                                onKeyDown={(e) => {
                                  if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();  
                                  }
                                }}
                                InputProps={{
                                  endAdornment: <Typography color='textSecondary'>%</Typography>
                                }}
                                helperText={errors.rate && touched.rate ? errors.rate : ''}
                                error={errors.rate && touched.rate}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <FormControlLabel
                                control={<Field component={Switch} color='primary' type='checkbox' name='fixedRate' />}
                                label='Fixed'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <Box display='flex' justifyContent='space-between'>
                              <TextField
                                name='deposit'
                                required
                                id='deposit'
                                label='Deposit'
                                value={values.deposit}
                                type='number'
                                onKeyDown={(e) => {
                                  if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();  
                                  }
                                }}
                                InputProps={{
                                  endAdornment: <Typography color='textSecondary'>%</Typography>
                                }}
                                helperText={errors.deposit && touched.deposit ? errors.deposit : ''}
                                error={errors.deposit && touched.deposit}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <FormControlLabel
                                control={<Field component={Switch} color='primary' type='checkbox' name='depositCash' />}
                                label='Cash'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <Box display='flex' justifyContent='space-between'>
                              <TextField
                                name='balloonPayment'
                                required
                                id='balloonPayment'
                                label='Balloon Payment'
                                value={values.balloonPayment}
                                helperText={errors.balloonPayment && touched.balloonPayment ? errors.balloonPayment : ''}
                                error={errors.balloonPayment && touched.balloonPayment}
                                onBlur={handleBlur}
                                type='number'
                                InputProps={{
                                  endAdornment: <Typography color='textSecondary'>%</Typography>
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();  
                                  }
                                }}
                                onChange={handleChange}
                              />
                              <FormControlLabel
                                control={<Field component={Switch} color='primary' type='checkbox' name='balloonPaymentCash' />}
                                label='Cash'
                              />
                            </Box>
                          </Grid>

                          <Grid item container spacing={4} xs={12}>
                            <Grid item container spacing={4} xs={12} md={6}>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  fullWidth
                                  required
                                  id='monthlyInstallment'
                                  label='Monthly Installment'
                                  value={values.monthlyInstallment}
                                  onChange={handleChange}
                                  placeholder={'eg. 200 000'}
                                  error={errors.monthlyInstallment && touched.monthlyInstallment}
                                  helperText={errors.monthlyInstallment && touched.monthlyInstallment ? 'Please enter a valid amount' : ''}
                                  InputProps={{
                                    inputComponent: CurrencyInput
                                  }}
                                />

                                <TextField
                                  fullWidth
                                  name='guaranteedValue'
                                  id='guaranteedValue'
                                  label='Guaranteed Value'
                                  value={values.guaranteedValue}
                                  type='text'
                                  helperText={errors.guaranteedValue && touched.guaranteedValue ? errors.guaranteedValue : ''}
                                  error={errors.guaranteedValue && touched.guaranteedValue}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <TextField
                                  name='term'
                                  required
                                  id='term'
                                  label='Installments in Months'
                                  value={values.term}
                                  type='number'
                                  onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') {
                                      e.preventDefault();  
                                    }
                                  }}
                                  // InputProps={{
                                  //   endAdornment: (
                                  //     <Typography color="textSecondary">
                                  //       Months
                                  //     </Typography>
                                  //   )
                                  // }}
                                  onChange={handleChange}
                                  helperText={errors.term && touched.term ? errors.term : ''}
                                  error={errors.term && touched.term}
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  fullWidth
                                  name='assistance'
                                  id='assistance'
                                  label='Assistance'
                                  value={values.assistance}
                                  type='text'
                                  helperText={errors.assistance && touched.assistance ? errors.assistance : ''}
                                  error={errors.assistance && touched.assistance}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  fullWidth
                                  id='totalCost'
                                  label='Total Cost'
                                  value={values.totalCost}
                                  onChange={handleChange}
                                  placeholder={'eg. 200 000'}
                                  error={errors.totalCost && touched.totalCost}
                                  helperText={errors.totalCost && touched.totalCost ? 'Please enter a valid amount' : ' '}
                                  InputProps={{
                                    inputComponent: CurrencyInput
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                name='termsAndConditions'
                                id='termsAndConditions'
                                required
                                variant='outlined'
                                multiline
                                rows={18}
                                label='Terms and Conditions'
                                value={values.termsAndConditions}
                                helperText={errors.termsAndConditions && touched.termsAndConditions ? errors.termsAndConditions : ''}
                                error={errors.termsAndConditions && touched.termsAndConditions}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12}>
                        <Divider variant='fullWidth' />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant='h6' style={{ flex: '1 1' }} component='div' gutterBottom>
                          S.E.O Configuration & Other Settings
                        </Typography>
                      </Grid>

                      <Grid item spacing={4} container xs={12}>
                        <Grid item md={6} xs={12}>
                          <Field
                            component={DatePicker}
                            autoOk
                            style={{ marginTop: '1rem' }}
                            format='dd/MM/yyyy'
                            label='From Date'
                            maxDate={values.dateTo}
                            name='dateFrom'
                          />

                          <Field
                            component={DatePicker}
                            autoOk
                            style={{ marginTop: '1rem' }}
                            format='dd/MM/yyyy'
                            label='End Date'
                            minDate={values.dateFrom}
                            name='dateTo'
                          />

                          <TextField
                            fullWidth
                            name='crmOverride'
                            id='crmOverride'
                            label='CRM Override'
                            value={values.crmOverride}
                            type='text'
                            helperText={errors.crmOverride && touched.crmOverride ? errors.crmOverride : ''}
                            error={errors.crmOverride && touched.crmOverride}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <TextField
                            fullWidth
                            name='campaignCode'
                            id='campaignCode'
                            label='Campaign Code'
                            value={values.campaignCode}
                            type='text'
                            helperText={errors.campaignCode && touched.campaignCode ? errors.campaignCode : ''}
                            error={errors.campaignCode && touched.campaignCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            name='redirectUrl'
                            id='redirectUrl'
                            label='Redirect URL'
                            value={values.redirectUrl}
                            type='text'
                            helperText={errors.redirectUrl && touched.redirectUrl ? errors.redirectUrl : ''}
                            error={errors.redirectUrl && touched.redirectUrl}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <TextField
                            name='metaKeywords'
                            fullWidth
                            id='metaKeywords'
                            label='Meta Keywords'
                            value={values.metaKeywords}
                            type='text'
                            InputProps={{
                              endAdornment: <Typography color='textSecondary'>100 Chars</Typography>
                            }}
                            helperText={
                              errors.metaKeywords && touched.metaKeywords
                                ? errors.metaKeywords
                                : 'Include commas, max 3 words. CSP will add province data to these keywords'
                            }
                            error={errors.metaKeywords && touched.metaKeywords}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />

                          <TextField
                            name='metaDescription'
                            fullWidth
                            id='metaDescription'
                            label='Meta Description'
                            value={values.metaDescription}
                            type='text'
                            InputProps={{
                              endAdornment: <Typography color='textSecondary'>160 Chars</Typography>
                            }}
                            helperText={
                              errors.metaDescription && touched.metaDescription
                                ? errors.metaDescription
                                : 'Snippet used in Google, Facebook, Twitter etc keep it short and punchy'
                            }
                            error={errors.metaDescription && touched.metaDescription}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider variant='fullWidth' />
                      </Grid>

                      <Grid item xs={12} md={6} style={{height: '20rem', overflowY: 'scroll'}}>
                        <Typography variant='body' gutterBottom>
                          Select the dealers to link to this offer
                        </Typography>
                        <table width='100%' border='1'>
                          <thead>
                            <tr className={classes.tr}>
                              <th className={classes.th}>Dealer Name</th>
                              <th className={classes.th}>Linked</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(isLoadingDealers || isLoading) && (
                              <tr>
                                <td colSpan={2} className={classes.tdCenter}>
                                  <CircularProgress />
                                </td>
                              </tr>
                            )}
                            {!isLoadingDealers && !isLoading && !(relatedDealers?.length > 0) && (
                              <tr>
                                <td colSpan={2} className={classes.tdCenter}>
                                  <Typography variant='caption'>No other dealers in this motorgroup</Typography>
                                </td>
                              </tr>
                            )}
                            {!isLoadingDealers &&
                              !isLoading &&
                              relatedDealers?.length > 0 &&
                              relatedDealers
                                .sort((a, b) => a?.name.localeCompare(b?.name))
                                .map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className={classes.td}>
                                        <div key={`${index}-1`}>{item.name}</div>
                                      </td>
                                      <td className={classes.tdCenter}>
                                        <div key={`${index}-2`}>
                                          <Checkbox
                                            name={item.name}
                                            color='primary'
                                            checked={item.isTicked}
                                            disabled={isSubmitting}
                                            onChange={() => {
                                              toggleRelatedDealer(item.dealerId);
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </Grid>

                      <Grid item container justify='flex-end' xs={12}>
                        <Box mr={3}>
                          <Button
                            onClick={() => {
                              handleReset();
                              history.push('/offers');
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                        <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
}
