import { Box, Button, Divider, Grid, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@mui/material/Link';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import * as Yup from 'yup';
import { BASE_URI } from '../../../shared/Constants';
import FileUploadInput from '../../../shared/FileUploadInput';
import ImageService from '../../../shared/ImageService';
import ImageUploadInput from '../../../shared/ImageUploadFormInput';
import { CurrencyInput } from '../../../shared/TextMask';

export default function DerivativeForm({ onSubmit, cancel, data, modelId, formType }) {
  const useStyles = makeStyles((theme) => ({
    textArea: {
      fontFamily: 'monospace',
      padding: '10px',
      fontSize: '12px',
      overflowY: 'scroll',
      whiteSpace: 'pre-wrap',
      backgroundColor: '#f4f4f4',
      border: '1px solid #ccc',
      borderRadius: '5px'
    }
  }));

  const initialValues = !data
    ? {
        image: '',
        title: '',
        subtitle: '',
        highlights: '',
        variantId: '',
        variant: '',
        priceRange: '',
        automaticImage: '',
        automaticPrice: 0,
        manualImage: '',
        manualPrice: 0,
        specSheet: {},
        specDoc: {},
        financeDetails: {},
        imageAltTag: '',
        manualImageAltTag: '',
        automaticImageAltTag: ''
      }
    : {
        ...data,
        variant: { id: data?.variantId || 0, variantName: data?.variantName || '' },
        specDoc: !data?.specSheet?.document?.filePath?.length
          ? {}
          : new File(['No contents'], data.specSheet.document.fileName, { type: 'application/pdf' })
      };

  const [formValues, setFormValues] = useState(initialValues);
  const [derivativeArray, setDerivativeArray] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    const getVariants = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/variants`, {
          params: {
            modelId: modelId
          }
        });
        // Only display current variants
        let list = result?.data?.list?.filter((v) => compareVariantYear(v));
        setDerivativeArray(list);

        let variant = list.find((v) => v.id == initialValues.variantId);
        setFormValues({
          ...initialValues,
          variant
        });
      } catch (error) {
        enqueueSnackbar('Unable to load vehicle variants', { variant: 'error' });
      }
    };

    getVariants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  const compareVariantYear = (variant) => {
    let year = new Date().getFullYear() - 1;
    if (!variant?.discontinueYear && variant?.introYear) {
      return true;
    } else if (variant?.discontinueYear) {
      var dscYear = parseInt(variant?.discontinueYear);
      if (dscYear >= year) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  const getFilePath = async (doc) => {
    let filePath = null;

    try {
      var base64Image = doc instanceof File ? await ImageService.getBase64(doc) : doc;

      filePath = base64Image;
    } catch (error) {
      enqueueSnackbar('Failed to get spec sheet file path: ' + error, { variant: 'error' });
    }

    return filePath;
  };

  const handleVariantChange = (e, value, setFieldValue) => {
    setFieldValue('variant', e.target.value);
    setFieldValue('variantId', value.props.id);
    setFieldValue('priceRange', value.props.value.priceRange);
  };

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      onSubmit={async (values, actions) => {
        if (!values.specDoc && values.specSheet?.document != null) {
          values.specSheet.document = null;
        } else if (values.specDoc?.path) {
          values.specSheet = {
            ...values.specSheet,
            document: {
              ...values.specSheet?.document,
              fileName: values.specDoc.name,
              filePath: await getFilePath(values.specDoc)
            }
          };
        }

        if (values.financeDetails) {
          values.financeDetails.balloonPayment = values.financeDetails.balloonPayment || 0;
          values.financeDetails.deposit = values.financeDetails.deposit || 0;
          values.financeDetails.rate = values.financeDetails.rate || 0;
        }

        const { image, automaticImage, manualImage } = values;

        if (
          (formType === 'addDerivative' || formType === 'editDerivative') &&
          (image?.image || automaticImage?.image || manualImage?.image)
        ) {
          values.image = image?.image || image;
          values.fileName = image.fileName;

          values.automaticImage = automaticImage?.image || automaticImage;
          values.automaticImageName = automaticImage.fileName;

          values.manualImage = manualImage?.image || manualImage;
          values.manualImageName = manualImage.fileName;
        }

        onSubmit(values, () => {
          actions.resetForm();
          actions.setSubmitting(false);
        });
      }}
      validationSchema={Yup.object().shape({
        variant: Yup.string().required('Select a variant for the vehicle model.'),
        title: Yup.string().required('Enter a title for the Variant'),
        image: Yup.string().required('Vehicle image is required'),
        automaticImage: Yup.string()
          .notRequired()
          .test('oneOfRequired', 'At least a manual or automatic preview image is required', function (value) {
            return this.parent.automaticImage || this.parent.manualImage;
          }),
        manualImage: Yup.string()
          .notRequired()
          .test('oneOfRequired', 'At least a manual or automatic preview image is required', function (value) {
            return this.parent.automaticImage || this.parent.manualImage;
          }),
        automaticPrice: Yup.string()
          .notRequired()
          .test('oneOfRequired', 'A price for the automatic transmission needs to be specified', function (value) {
            return (this.parent.manualImage && !this.parent.automaticImage) || (this.parent.automaticPrice && this.parent.automaticImage);
          })
      })}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => {
        const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;
        return (
          <Form>
            <Typography variant='h6' gutterBottom>
              {data ? 'Edit' : 'Create'} Variant
            </Typography>

            <Box mb={3}>
              <Grid spacing={4} container justify='space-around' direction='row'>
                <Grid item xs={12} md={6}>
                  <ImageUploadInput title='Variant Preview Image' required fieldName={'image'} error={errors.image && touched.image} />
                  <TextField
                    fullWidth
                    name='imageAltTag'
                    id='imageAltTag'
                    label='Alt Tag'
                    value={values.imageAltTag}
                    type='text'
                    helperText={errors.imageAltTag && touched.imageAltTag ? errors.imageAltTag : 'Alt tag for SEO'}
                    error={errors.imageAltTag && touched.imageAltTag}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <br></br>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div'>
                      Highlights
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      '& .ql-container': {
                        height: '250px',
                        overflow: 'auto'
                      }
                    }}
                  >
                    <ReactQuill
                      value={values.highlights}
                      onChange={(value) => setFieldValue('highlights', value)}
                      modules={{
                        toolbar: [
                          [{ font: [] }], // Font family
                          [{ size: ['small', false, 'large', 'huge'] }], // Font size
                          ['bold', 'italic', 'underline'], // Text styling
                          [{ color: [] }, { background: [] }], // Font color and background color
                          [{ list: 'ordered' }, { list: 'bullet' }], // Lists
                          ['blockquote'], // Blockquote
                          ['link'], // Links
                          ['clean'] // Clear formatting
                        ]
                      }}
                      formats={[
                        'font', // Font family
                        'size', // Font size
                        'bold',
                        'italic',
                        'underline', // Text styling formats
                        'color', // Font color
                        'background', // Background color
                        'list',
                        'bullet', // List formats
                        'blockquote', // Blockquote
                        'link', // Links
                        'clean' // Clear formatting
                      ]}
                      placeholder='Write your highlights here...'
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name='title'
                    required
                    id='title'
                    label='Title'
                    value={values.title}
                    type='text'
                    helperText={errors.title && touched.title ? 'Please enter a title for the range option' : ''}
                    error={errors.title && touched.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <TextField
                    fullWidth
                    name='subtitle'
                    id='subtitle'
                    label='Subtitle'
                    value={values.subtitle}
                    type='text'
                    error={errors.subtitle && touched.subtitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    fullWidth
                    id='variant'
                    select
                    required
                    placeholder='Please select variant'
                    label='Variant'
                    disabled={formType.toLowerCase().includes('edit')}
                    value={values.variant}
                    helperText={errors.variant && touched.variant ? 'Please select variant for the vehicle model' : ''}
                    error={errors.variant && touched.variant}
                    onBlur={handleBlur('variant')}
                    onChange={(e, child) => handleVariantChange(e, child, setFieldValue)}
                  >
                    {derivativeArray.map((option) => (
                      <MenuItem key={option.id} id={option.id} value={option} style={option.isBid4Cars ? { background: 'lightgrey' } : {}}>
                        {`${option?.variantName} ${option?.transmission ? `(${option?.transmission})` : ''} ${
                          option?.mmCode ? `- (${option?.mmCode})` : ''
                        }`}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    fullWidth
                    required
                    id='priceRange'
                    label='Price Range'
                    value={values.priceRange}
                    onChange={handleChange}
                    placeholder={'eg. 200 000'}
                    error={errors.priceRange && touched.priceRange}
                    helperText={errors.priceRange && touched.priceRange ? 'Please enter a valid amount' : ''}
                    InputProps={{
                      inputComponent: CurrencyInput
                    }}
                  />

                  <div>
                    <Link href={values.specSheet?.document?.filePath}>
                      <strong>{values.specSheet?.document?.fileName}</strong>
                    </Link>
                  </div>
                  <FileUploadInput title='Specifications Sheet' fieldName={'specDoc'} />
                </Grid>
              </Grid>
            </Box>

            <Divider />

            {/*finance*/}
            <Box mt={3} mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='subtitle2' gutterBottom>
                    Finance Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' gutterBottom>
                    Note that these figures will override the corresponding global figures.
                  </Typography>
                </Grid>
              </Grid>
              <Grid spacing={4} container direction='row' justify='space-between'>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id='financeDetails.balloonPayment'
                    label='Balloon Payment'
                    value={values.financeDetails?.balloonPayment}
                    onChange={handleChange}
                    placeholder={'eg. 10'}
                    InputProps={{
                      type: 'number',
                      endAdornment: '%'
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id='financeDetails.deposit'
                    label='Deposit'
                    value={values.financeDetails?.deposit}
                    onChange={handleChange}
                    placeholder={'eg. 20'}
                    InputProps={{
                      type: 'number',
                      endAdornment: '%'
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id='financeDetails.rate'
                    label='Rate'
                    value={values.financeDetails?.rate}
                    onChange={handleChange}
                    placeholder={'eg. 7'}
                    InputProps={{
                      type: 'number',
                      endAdornment: '%'
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Divider />

            {/*variant*/}
            <Box mt={3}>
              <Grid spacing={4} container justify='space-around' direction='row'>
                <Grid item container direction='column' xs={12}>
                  <Typography variant='subtitle2' gutterBottom>
                    Transmission Options
                  </Typography>
                  <Typography variant='caption' gutterBottom>
                    By adding an image preview below this will enable the transmission type for this vehicle variant.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ImageUploadInput
                    title='Manual Preview Image'
                    fieldName={'manualImage'}
                    errorMsg={errors.manualImage}
                    error={errors.manualImage && touched.manualImage}
                  />
                  <TextField
                    fullWidth
                    name='manualImageAltTag'
                    id='manualImageAltTag'
                    label='Alt Tag'
                    value={values.manualImageAltTag}
                    type='text'
                    helperText={errors.manualImageAltTag && touched.manualImageAltTag ? errors.manualImageAltTag : 'Alt tag for SEO'}
                    error={errors.manualImageAltTag && touched.manualImageAltTag}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <ImageUploadInput
                      title='Automatic Preview Image'
                      fieldName={'automaticImage'}
                      errorMsg={errors.automaticImage}
                      error={errors.automaticImage && touched.automaticImage}
                    />
                    <TextField
                      fullWidth
                      name='automaticImageAltTag'
                      id='automaticImageAltTag'
                      label='Alt Tag'
                      value={values.automaticImageAltTag}
                      type='text'
                      helperText={
                        errors.automaticImageAltTag && touched.automaticImageAltTag ? errors.automaticImageAltTag : 'Alt tag for SEO'
                      }
                      error={errors.automaticImageAltTag && touched.automaticImageAltTag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {values.automaticImage && (
                      <TextField
                        style={{ marginTop: '1.5rem' }}
                        fullWidth
                        id='automaticPrice'
                        label='Price for Automatic Transmission'
                        value={values.automaticPrice}
                        onChange={handleChange}
                        placeholder={'eg. 200 000'}
                        error={errors.automaticPrice && touched.automaticPrice}
                        helperText={errors.automaticPrice && touched.automaticPrice ? errors.automaticPrice : ''}
                        InputProps={{
                          inputComponent: CurrencyInput
                        }}
                      />
                    )}
                  </div>
                </Grid>

                <Grid item container justify='flex-end' xs={12}>
                  <Box mr={3}>
                    <Button
                      onClick={() => {
                        handleReset();
                        cancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}
