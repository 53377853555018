import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { BASE_URI } from '../../shared/Constants';
import { DealerContext } from '../../shared/DealerContext';

export default function BlogFilter(searchString, status, pageNumber, pageSize, order = 'asc', orderBy = 'headline') {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [total, setTotal] = useState(0);
    const { dealerId } = useContext(DealerContext);

    useEffect(() => {
        setBlogs([]);
    }, [searchString, pageNumber, pageSize, order, orderBy,  dealerId, status]);

    useEffect(() => {
        setError(false);
        const params = {
            pageNumber: pageNumber + 1,
            pageSize,
            order,
            orderBy,
            status,
            dealerId
        };
        
        if (searchString) {
            params.searchString = searchString;
        }
        if (status) { params.status = status }

        setLoading(true);


        axios({
            method: 'GET',
            url: `${BASE_URI}/Blog`,
            params
        }).then((response) => {
            if (response.data?.list) {
                setBlogs(response.data.list);
                setTotal(response.data.total);
            }
        }).catch(error => {
            if (axios.isCancel(error)) return;
            setError(true);
        }).finally(() => {
            setLoading(false);
        });
    }, [searchString, pageNumber, pageSize, order, orderBy, dealerId, status]);

    return { loading, error, blogs, total };
}
