import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Toolbar
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@mui/material';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-material-ui';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { BASE_URI, PHONE_REGEX, WEBSITE_REGEX } from '../../shared/Constants';
import TextMaskCustom, { CurrencyInput } from '../../shared/TextMask';
import { DealerContext } from '../../shared/DealerContext';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  activeUsersContainer: {},
  activeUserContainer: {}
}));

function getStyles(arrayItem, selectArray, theme) {
  return {
    fontWeight: selectArray.indexOf(arrayItem) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}

export default function DealerAdminForm({ history, match }) {
  const classes = useStyles();
  const { id } = match.params;
  const isAddMode = !id;
  const theme = useTheme();
  const [userArr, setUserArr] = useState([]);
  const [brandsArr, setBrandsArr] = useState([]);
  const [brandNamesArr, setBrandNamesArr] = useState([]);
  const [crmArr, setCrmArr] = useState([]);
  const [motorGroupArr, setMotorGroupArr] = useState([]);
  const [selectedMotorGroup, setSelectedMotorGroup] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [originalUserArr, setOriginalUserArr] = useState([]);
  
  const { organizations } = useContext(DealerContext);

  const [removedUsers, setRemovedUsers] = useState([]);
  const [originalBrandNamesArr, setOriginalBrandNamesArr] = useState([]);
  const [country, setCountry] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [isDeleteUser, setIsDeleteUser] = useState(false);

  const initialValues = {
    id: '',
    name: '',
    active: false,
    principal: '',
    email: '',
    motorgroup: '',
    motorgroupId: 0,
    newCarsPhoneNumber: '',
    usedCarsPhoneNumber: '',
    websiteUrl: '',
    activeUsers: [],
    addressLongitude: '',
    addressLatitude: '',
    lookupCountryId: 112,
    lookupRegionId: 0,
    lookupCityId: 0,
    operatingHours: '',
    //relatedBrands: [],
    relatedBrandNames: [],
    organizationIds: [],
    description: '',
    lastModified:'',
    address: {
      streetAddress: '',
      streetAddressPostal: '',
      postalAddress: '',
      postalCode: ''
    },
    emails: {
      financeEmail: '',
      documentEmail: '',
      adminEmail: '',
      digitalDealersEmails: ''
    },

    crm: {
      sendTo: 0,
      crmDealerId: 0,
      pinCode: 0,
      autoSend: false,
      floor: ''
    }
  };
  const [formFields, setFormFields] = useState(initialValues);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };

  const getUsersNotDeleted = (arr1, arr2) => {
    if (!arr1?.length) return arr2 || [];
    if (!arr2?.length) return arr1 || [];

    // Create a Set of IDs from arr2 for quick lookup
    const arr2Ids = new Set(arr2.map((user) => user.id));
    // Filter arr1 to include only users whose IDs are not in arr2Ids
    return arr1.filter((user) => !arr2Ids.has(user.id));
  };

  const getUser = async () => {
    try {
      const result = await axios.get(`${BASE_URI}/user/users`, {
        params: {
          id
        }
      });

      setUserArr(result?.data ? result?.data : []);
    } catch (error) {
      // addAlert('Unables to load vehicle categories')
      //history.push('..')
    }
  };

  useEffect(() => {
    const getMakes = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/Makes`);

        setBrandsArr([...result.data.list]);
        setBrandNamesArr([...result.data.list.map((b) => b.name)]);
      } catch (error) {
        // addAlert('Unables to load vehicle categories')
        //history.push('..')
      }
    };

    const getCrm = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/lookup/crm`, {
          params: {
            id
          }
        });

        setCrmArr(result.data);
      } catch (error) {
        // addAlert('Unables to load vehicle categories')
        //history.push('..')
      }
    };

    const getMotorgroups = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/lookup/motorgroup`, {
          params: {
            id
          }
        });
        setMotorGroupArr(result.data);
        return result.data;
      } catch (error) {
        // addAlert('Unables to load vehicle categories')
        //history.push('..')
      }
    };

    const getLocations = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/ApplicationLookup/rsaLocations`);

        setCountry(result?.data?.country ?? { id: 112 });
        setRegions(result?.data?.regions ?? []);
        setCities(result?.data?.cities ?? []);
        setFormFields({
          ...formFields,
          lookupCountryId: result?.data?.county?.id || 112
        });
      } catch (error) {
        // addAlert('Unables to load vehicle categories')
        //history.push('..')
      }
    };

    getLocations();
    getUser();
    getMakes().then(() => {
      // Mimick the mult-select component's "select action" for the initial brands assigned to this dealer
      /*
                    ISSUE (2021-10-19): the MUI multi-select component does not like complex objects. When programmatically "selecting"
                    the initial brands, the items did not appear as selected in the components dropdown menu. And when attempting to
                    de-select these initial brands, a duplicate selection would occur.

                    WORK-AROUND (2021-10-19): flattening the mult-select component's menu items to an array of strings (instead of an
                    array of objects with ID & Name properties) seem to work 100%.
                */

      if (brandNamesArr?.length > 0 && formFields?.relatedBrandNames?.length > 0) {
        setFormFields({
          ...formFields,
          ['relatedBrandNames']: brandNamesArr.filter((b) => formFields.relatedBrandNames.includes(b))
          //["relatedBrands"]: getRelatedBrandsFromMakes(formFields.relatedBrands.map(rb => rb.id))
        });
      }

      if (!isAddMode) {
        getDealer();
      }
    });

    getCrm();

    getMotorgroups().then((groups) => {
      setSelectedMotorGroup(groups.find((g) => g?.id == formFields.motorgroupId));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields.motorgroupId, id]);

  const editDealer = async (id, fields, resetForm) => {
    try {
      const result = await axios.put(`${BASE_URI}/dealers/${id}`, fields);
      resetForm();
      enqueueSnackbar('Successfully updated dealer', { variant: 'success' });
      history.goBack();
      return result;
    } catch (error) {
      enqueueSnackbar(`Failed to update dealer: ${error?.data?.message ?? error ?? 'no error details available'}`, { variant: 'error' });
    }
  };
  const isActiveUser = (user) => {
    var existingUser = originalUserArr.find((u) => {
      return u?.id === user?.id;
    });
    if (existingUser) return true;
    return false;
  };

  const getDealer = async () => {
    try {
      const result = await axios.get(`${BASE_URI}/dealers/${id}`);

      setFormFields({
        ...result.data,
        ['activeUsers']: result.data.activeUsers,
        //["relatedBrands"]: result.data.relatedBrands,
        ['relatedBrandNames']: result.data.relatedBrands.map((b) => b.name),
        id: id,
        lookupCountryId: result?.data?.lookupCountryId || formFields.lookupCountryId,
        lookupRegionId: result?.data?.lookupRegionId || formFields.lookupRegionId,
        lookupCityId: result?.data?.lookupCityId || formFields.lookupCityId
      });
      setOriginalUserArr(result.data.activeUsers);
      //setOriginalBrandsArr(result.data.relatedBrands);
      setOriginalBrandNamesArr(result.data.relatedBrands.map((b) => b.name));

      return result;
    } catch (error) {
      //enqueueSnackbar(error.data.message, {variant: 'error'});
      enqueueSnackbar(`Failed to get dealer details: ${error?.data?.message ?? error ?? 'no error details available'}`, {
        variant: 'error'
      });
    } finally {
    }
  };

  const createDealer = async (fields, resetForm) => {
    try {
      const result = await axios.post(`${BASE_URI}/dealers`, fields);

      if (result && result.data && result.data.id) {
        var promise1 = processActiveUsers(result.data.id, fields.activeUsers);
        var promise2 = processRelatedBrands(result.data.id, fields.relatedBrandNames);

        Promise.allSettled([promise1, promise2]).then(() => {
          resetForm();
          enqueueSnackbar('Successfully added dealer', { variant: 'success' });
          history.goBack();
        });
      } else {
        resetForm();
        enqueueSnackbar('Successfully added dealer', { variant: 'success' });
        history.goBack();
      }

      return result;
    } catch (error) {
      enqueueSnackbar(`Failed to create dealer: ${error?.response?.data }`, { variant: 'error' });
    }
  };

  const operationArr = (list1, list2, isUnion = false) => list1.filter((a) => isUnion === list2.some((b) => a.id === b.id));

  const inBothArr = (list1, list2) => operationArr(list1, list2, true);
  const inFirstOnlyArr = operationArr;
  const inSecondOnlyArr = (list1, list2) => inFirstOnlyArr(list2, list1);

  function onSubmit(fields, { setStatus, resetForm }) {
    setStatus();
    if (selectedMotorGroup) {
      if (isAddMode) {
        createDealer(fields, resetForm);
      } else {
        if (fields.id) {
          var promise1 = processActiveUsers(fields.id, fields.activeUsers);
          var promise2 = processRelatedBrands(fields.id, fields.relatedBrandNames);

          Promise.allSettled([promise1, promise2]).then(() => {
            editDealer(id, fields, resetForm);
          });
        } else {
          enqueueSnackbar('Dealer ID not available', { variant: 'error' });
        }
      }
    } else {
      enqueueSnackbar('Please select a motor group!', { variant: 'warning' });
    }
  }

  const processActiveUsers = async (dealerId, activeUsers) => {
    let isEqual = _.isEqual(_.sortBy(activeUsers, 'id'), _.sortBy(originalUserArr, 'id'));

    if ((originalUserArr.length > 0 || activeUsers.length > 0) && !isEqual) {
      let tmpNewUsers = inSecondOnlyArr(originalUserArr, activeUsers);
      //	let tmpDeletedUsers = inFirstOnlyArr(originalUserArr, activeUsers);

      const newUsers = tmpNewUsers.map((x) => x.id);
      //const deletedUsers = tmpDeletedUsers.map((x) => x.id);

      return updateDealerUserRelationship(dealerId, newUsers, []);
    }
  };

  const updateDealerUserRelationship = async (dealerId, newUsers, deletedUsers) => {
    try {
      const promise = axios.post(`${BASE_URI}/dealers/DealersUsersUpdate`, {
        dealerId: parseInt(dealerId),
        newUsers
        //deletedUsers,
      });

      return promise;
      //enqueueSnackbar(result.data.message, {variant: 'success'});
    } catch (error) {
      //enqueueSnackbar(error.data.message, {variant: 'error'});
      enqueueSnackbar(`Failed to update active users: ${error?.data?.message ?? error ?? 'no error details available'}`, {
        variant: 'error'
      });
    }
  };

  const processRelatedBrands = async (dealerId, relatedBrandNames) => {
    //let isEqual = _.isEqual(_.sortBy(relatedBrands, "id"), _.sortBy(originalBrandsArr, "id"));
    let isEqual = _.isEqual(_.sortBy(relatedBrandNames), _.sortBy(originalBrandNamesArr));

    if ((originalBrandNamesArr.length > 0 || relatedBrandNames.length > 0) && !isEqual) {
      let updateBrands = [];

      relatedBrandNames.forEach((rb) =>
        updateBrands.push({
          dealerId: parseInt(dealerId),
          makeId: getMakeIdFromBrandName(rb)
        })
      );

      updateDealerBrandRelationship(updateBrands);
    }
  };

  const updateDealerBrandRelationship = async (updateBrandsList) => {
    try {
      return axios.put(`${BASE_URI}/DealerMake`, updateBrandsList);
    } catch (error) {
      enqueueSnackbar(`Failed to update dealer-brand assignements: ${error?.data?.message ?? error ?? 'no error details available'}`, {
        variant: 'error'
      });
    }
  };

  const getMakeIdFromBrandName = (name) => {
    try {
      var make = brandsArr.find((b) => b.name == name);
      return make?.id || -1;
    } catch (error) {
      console.error('Exception caught for getting make ID from brand name: ', error);
      return -1;
    }
  };

  const removeUser = (userId) => {
    setFormFields({
      ...formFields,
      activeUsers: formFields?.activeUsers.filter((u) => {
        return u.Id !== userId;
      })
    });

    const user = userArr.find((u) => {
      return u?.id == userId;
    });
    if (user) {
      removedUsers.push(user);
      setRemovedUsers(removedUsers);
    }
  };

  const onRemoveUser = async (userId) => {
    setIsDeleteUser(true);
    if (isAddMode) {
      removeUser(userId);
      return;
    }

    try {
      const result = await axios.delete(`${BASE_URI}/dealers/RemoveFromDealer?userId=${userId}&dealerId=${id}`);
      if (result?.status === 200) {
        removeUser(userId);
        enqueueSnackbar('User removed successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('An unexpected error occured', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(`Failed to remove user"}`, { variant: 'error' });
    } finally {
      setIsDeleteUser(false);
    }
  };

  const handleOrganizationChange = (selectedIds, setFieldValue) => {
    setFieldValue('organizationIds', selectedIds);
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <Formik
            initialValues={formFields}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              id: Yup.string(),
              name: Yup.string().required('Dealership Name is required'),
              addressLongitude: Yup.string().required('Longitude is required'),
              addressLatitude:  Yup.string().required('Latitude is required'),
              address: Yup.object({
                streetAddress: Yup.string().required('Street Address is required'),
                streetAddressPostal: Yup.string().required('Street Postal Code is required')
              }),
              lookupCityId: Yup.number()
              .required('City is required').positive('City is a required field'),
              lookupRegionId: Yup.number()
              .required('Province is required').positive('Province is a required field'),
              email: Yup.string().email('Email is invalid').required('Email is required'),
              principal: Yup.string().required('Dealership principal is required'),
              newCarsPhoneNumber: Yup.string().matches(PHONE_REGEX, 'Phone number is not valid').required('Phone number is required'),
              usedCarsPhoneNumber: Yup.string().matches(PHONE_REGEX, 'Phone number is not valid').required('Phone number is required'),
              websiteUrl: Yup.string().matches(WEBSITE_REGEX, 'URL is not valid').required('Dealer website is required'),
              emails: Yup.object({
                financeEmail: Yup.string()
                  .transform((value) => (value ? value.trim() : ''))
                  .test('is-valid-email-list', 'One or more emails are invalid', function (value) {
                    if (!value) return true;
                    const emails = value.split(/[\s,]+/).filter(Boolean);
                    const invalidEmails = emails.filter((email) => !Yup.string().email().isValidSync(email));
                    return invalidEmails.length === 0;
                  })
                  .required('Finance email is required'),

                digitalDealersEmails: Yup.string()
                  .transform((value) => (value ? value.trim() : ''))
                  .test('is-valid-email-list', 'One or more emails are invalid', function (value) {
                    if (!value) return true;
                    const emails = value.split(/[\s,]+/).filter(Boolean);
                    const invalidEmails = emails.filter((email) => !Yup.string().email().isValidSync(email));
                    return invalidEmails.length === 0;
                  })
                  .required('Digital dealer email is required'),

                documentEmail: Yup.string().email('Document email is invalid').required('Document email is required'),

                adminEmail: Yup.string().email('Admin email is invalid').required('Admin email is required')
              })
            })}
          >
            {(props) => {
              const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;
              let motorGroupName = motorGroupArr.find((mg) => mg.id == values.motorgroupId)?.name || 'Selected Motorgroup';
              const filteredActiveUsers = values.activeUsers.filter(
                (activeUser) => !removedUsers.some((removedUser) => removedUser.id === activeUser.id)
              );

              values.activeUsers = filteredActiveUsers;

              return (
                <Form>
                  <Toolbar disableGutters>
                    <Typography variant='h6' style={{ flex: '1 1' }} component='div' gutterBottom>
                      {isAddMode ? 'Add' : 'Edit'} Dealer
                    </Typography>
                    <FormControlLabel
                      control={<Field component={Switch} color='primary' type='checkbox' name='active' id='active' />}
                      label='Active'
                    />
                  </Toolbar>
                  <Grid spacing={4} container direction='row'>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div'>
                        General:
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField hidden type='text' id='id' name='id' value={id} />
                      <TextField
                        fullWidth
                        name='name'
                        id='name'
                        label='Dealership Name'
                        value={values.name}
                        type='text'
                        helperText={errors.name && touched.name ? errors.name : ''}
                        error={errors.name && touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name='email'
                        id='email'
                        label='Email'
                        value={values.email}
                        type='email'
                        helperText={errors.email && touched.email ? errors.email : ''}
                        error={errors.email && touched.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name='principal'
                        id='principal'
                        label='Dealership Principal'
                        value={values.principal}
                        type='text'
                        helperText={errors.principal && touched.principal ? errors.principal : ''}
                        error={errors.principal && touched.principal}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name='websiteUrl'
                        id='websiteUrl'
                        label='Website'
                        value={values.websiteUrl}
                        type='text'
                        helperText={errors.websiteUrl && touched.websiteUrl ? errors.websiteUrl : ''}
                        error={errors.websiteUrl && touched.websiteUrl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label='New Cars Number'
                        value={values.newCarsPhoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.newCarsPhoneNumber}
                        helperText={errors.newCarsPhoneNumber ? 'Please enter mobile number' : ''}
                        name='newCarsPhoneNumber'
                        id='newCarsPhoneNumber'
                        InputProps={{
                          inputComponent: TextMaskCustom
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label='Used Cars Number'
                        value={values.usedCarsPhoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.usedCarsPhoneNumber}
                        helperText={errors.usedCarsPhoneNumber ? 'Please enter mobile number' : ''}
                        name='usedCarsPhoneNumber'
                        id='usedCarsPhoneNumber'
                        InputProps={{
                          inputComponent: TextMaskCustom
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InputLabel style={{ fontSize: '11px' }} id='dealers'>
                        Active Users for this dealer
                      </InputLabel>
                      <div className={classes.activeUsersContainer}>
                        {values.activeUsers
                          .filter((u) => {
                            return isActiveUser(u);
                          })
                          .map((value) => {
                            return (
                              <Chip key={value.id} label={value.name} className={classes.chip} onDelete={() => onRemoveUser(value?.id)} />
                            );
                          })}
                      </div>
                      <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                        <Select
                          labelId='activeUsers'
                          id='activeUsers'
                          label='Active Users for this dealer'
                          multiple
                          value={getUsersNotDeleted(values.activeUsers, removedUsers)}
                          onChange={handleChange('activeUsers')}
                          input={<Input id='activeUsers' />}
                          MenuProps={MenuProps}
                        >
                          {getUsersNotDeleted(userArr, originalUserArr).map((user) => (
                            <MenuItem key={user.id} value={user} style={getStyles(user, userArr, theme)}>
                              {user.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error id='component-error-text'>
                          {errors.activeUsers && touched.activeUsers && errors.activeUsers}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                        <InputLabel id='relatedBrandNames-label'>Related brands for this dealer</InputLabel>
                        <Select
                          labelId='relatedBrandNames-label'
                          id='relatedBrandNames'
                          multiple
                          value={values.relatedBrandNames}
                          onChange={handleChange('relatedBrandNames')}
                          input={<Input id='relatedBrandNames-select' />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {brandNamesArr.map((brand) => (
                            <MenuItem key={brand} value={brand} style={getStyles(brand, brandNamesArr, theme)}>
                              {brand}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error id='component-error-text'>
                          {errors.relatedBrandNames && touched.relatedBrandNames}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                          <Autocomplete
                            id='motorgroup'
                            name='motorgroup'
                            value={selectedMotorGroup}
                            options={motorGroupArr}
                            getOptionLabel={(option) => option.name || ''}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            onChange={(event, value) => {
                              setSelectedMotorGroup(value);
                              values.motorgroupId = value ? value.id : 0;
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label={ selectedMotorGroup?.name || motorGroupName} />
                            )}
                          />
                         
                        <FormHelperText error id='component-error-text'>
                          {errors.motorgroup && touched.motorgroup}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                        <Autocomplete
                          multiple
                          options={organizations}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name || ''}
                          value={organizations.filter((org) => values.organizationIds?.includes(org.id))}
                          onChange={(event, newValue) => {
                            const selectedIds = newValue.map((org) => org.id);
                            handleOrganizationChange(selectedIds, setFieldValue);
                          }}
                          filterOptions={(options, state) =>
                            options.filter((org) => org.name.toLowerCase().includes(state.inputValue.toLowerCase()))
                          }
                          renderInput={(params) => (
                            <TextField {...params} label='Dealer Organizations' placeholder='Search...' variant='outlined' fullWidth />
                          )}
                          renderOption={(props, option, { selected }) => (
                            <MenuItem {...props} key={option.id}>
                              <Checkbox checked={selected} />
                              <ListItemText primary={option.name} />
                            </MenuItem>
                          )}
                          renderTags={(selected, getTagProps) =>
                            selected.map((option, index) => <Chip key={option.id} label={option.name} {...getTagProps({ index })} />)
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name='description'
                        id='description'
                        variant='outlined'
                        multiline
                        minRows={9}
                        label='Description'
                        value={values.description}
                        helperText={errors.description && touched.description ? errors.description : ''}
                        error={errors.description && touched.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div'>
                        Address:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name='addressLongitude'
                        id='addressLongitude'
                        label='Longitude'
                        value={values.addressLongitude}
                        type='text'
                        helperText={errors.addressLongitude && touched.addressLongitude ? errors.addressLongitude : ''}
                        error={errors.addressLongitude && touched.addressLongitude}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name='addressLatitude'
                        id='addressLatitude'
                        label='Latitude'
                        value={values.addressLatitude}
                        type='text'
                        helperText={errors.addressLatitude && touched.addressLatitude ? errors.addressLatitude : ''}
                        error={errors.addressLatitude && touched.addressLatitude}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        name="address.streetAddress"
                        id="address.streetAddress"
                        variant="outlined"
                        label="Street Address"
                        type='text'
                        value={values.address?.streetAddress || ''}
                        helperText={errors.address?.streetAddress && touched.address?.streetAddress ? errors.address.streetAddress : ''}
                        error={Boolean(errors.address?.streetAddress && touched.address?.streetAddress)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>


                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name='address.streetAddressPostal'
                        id='address.streetAddressPostal'
                        label='Postal Code'
                        type='text'
                        value={values.address?.streetAddressPostal || ''}
                        helperText={errors.address?.streetAddressPostal && touched.address?.streetAddressPostal ? errors.address.streetAddressPostal : ''}
                        error={Boolean(errors.address?.streetAddressPostal && touched.address?.streetAddressPostal)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        name='address.postalAddress'
                        id='address.postalAddress'
                        variant='outlined'
                        type='text'
                        label='Postal Address'
                        value={values.address?.postalAddress || ''}
                        helperText={errors.address?.postalAddress && touched.address?.postalAddress ? errors.address.postalAddress : ''}
                        error={Boolean(errors.address?.postalAddress && touched.address?.postalAddress)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name='address.postalCode'
                        id='address.postalCode'
                        label='Postal Code'
                        type='text'
                        value={values.address?.postalCode || ''}
                        helperText={errors.address?.postalCode && touched.address?.postalCode ? errors.address.postalCode : ''}
                        error={Boolean(errors.address?.postalCode && touched.address?.postalCode)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        select
                        fullWidth
                        name='lookupRegionId'
                        id='lookupRegionId'
                        label='Province'
                        value={values.lookupRegionId}
                        type='text'
                        helperText={errors.lookupRegionId && touched.lookupRegionId ? errors.lookupRegionId : ''}
                        error={errors.lookupRegionId && touched.lookupRegionId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {regions.map((option) => (
                          <MenuItem key={option.id} id={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        select
                        fullWidth
                        name='lookupCityId'
                        id='lookupCityId'
                        label='City'
                        value={values.lookupCityId}
                        type='text'
                        helperText={errors.lookupCityId && touched.lookupCityId ? errors.lookupCityId : ''}
                        error={errors.lookupCityId && touched.lookupCityId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {cities
                          .filter((c) => (!values.lookupRegionId && values.lookupRegionId == 0) || c.regionID == values.lookupRegionId)
                          .map((option) => (
                            <MenuItem key={option.id} id={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div'>
                        Operating Hours:
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name='operatingHours'
                        id='operatingHours'
                        variant='outlined'
                        multiline
                        minRows={4}
                        label='Operating Hours'
                        value={values.operatingHours}
                        helperText={errors.operatingHours && touched.operatingHours ? errors.operatingHours : ''}
                        error={errors.operatingHours && touched.operatingHours}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/*EMAILS*/}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div'>
                        Emails:
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name='emails.financeEmail'
                        id='financeEmail'
                        label='Finance Email Addresses'
                        value={values.emails.financeEmail}
                        helperText={
                          errors?.emails?.financeEmail && touched?.emails?.financeEmail
                            ? errors.emails.financeEmail
                            : 'Comma delimited, no spaces'
                        }
                        error={errors?.emails?.financeEmail && touched?.emails?.financeEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name='emails.documentEmail'
                        id='documentEmail'
                        label='Document Email'
                        value={values.emails.documentEmail}
                        type='email'
                        helperText={errors.documentEmail && touched.documentEmail ? errors.documentEmail : ''}
                        error={errors.documentEmail && touched.documentEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name='emails.adminEmail'
                        id='adminEmail'
                        label='Admin Email'
                        value={values.emails.adminEmail}
                        type='email'
                        helperText={errors.adminEmail && touched.adminEmail ? errors.adminEmail : ''}
                        error={errors.adminEmail && touched.adminEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name='emails.digitalDealersEmails'
                        id='digitalDealersEmails'
                        label='Digital Dealer Email Addresses'
                        value={values.emails.digitalDealersEmails}
                        helperText={
                          errors?.emails?.digitalDealersEmails && touched?.emails?.digitalDealersEmails
                            ? errors.emails.digitalDealersEmails
                            : 'Comma delimited, no spaces'
                        }
                        error={errors?.emails?.digitalDealersEmails && touched?.emails?.digitalDealersEmails}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/*FINANCIAL INFO*/}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Typography variant='subtitle1' style={{ flex: '1 1' }} component='div'>
                        Financial Information:
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        required
                        name='financialInformation.offerAdminFee'
                        id='offerAdminFee'
                        label='Offer Admin Fee'
                        value={values.financialInformation.offerAdminFee}
                        onChange={handleChange}
                        placeholder={'eg. 1 000'}
                        error={errors.offerAdminFee && touched.offerAdminFee}
                        helperText={errors.offerAdminFee && touched.offerAdminFee ? 'Please enter a valid amount' : ''}
                        InputProps={{
                          inputComponent: CurrencyInput
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        required
                        name='financialInformation.offerServiceFee'
                        id='offerServiceFee'
                        label='Offer Service Fee'
                        value={values.financialInformation.offerServiceFee}
                        onChange={handleChange}
                        placeholder={'eg. 1 000'}
                        error={errors.offerServiceFee && touched.offerServiceFee}
                        helperText={errors.offerServiceFee && touched.offerServiceFee ? 'Please enter a valid amount' : ''}
                        InputProps={{
                          inputComponent: CurrencyInput
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        required
                        name='financialInformation.offerInterestRate'
                        id='offerInterestRate'
                        label='Offer Interest Rate (%)'
                        value={values.financialInformation.offerInterestRate}
                        onChange={handleChange}
                        placeholder={'eg. 10'}
                        error={errors.offerInterestRate && touched.offerInterestRate}
                        helperText={errors.offerInterestRate && touched.offerInterestRate ? 'Please enter a valid amount' : ''}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        required
                        name='financialInformation.deposit'
                        id='deposit'
                        label='Deposit (%)'
                        value={values.financialInformation.deposit}
                        onChange={handleChange}
                        placeholder={'eg. 10'}
                        error={errors.deposit && touched.deposit}
                        helperText={errors.deposit && touched.deposit ? 'Please enter a valid amount' : ''}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        required
                        name='financialInformation.balloonPayment'
                        id='balloonPayment'
                        label='Balloon Payment (%)'
                        value={values.financialInformation.balloonPayment}
                        onChange={handleChange}
                        placeholder={'eg. 10'}
                        error={errors.balloonPayment && touched.balloonPayment}
                        helperText={errors.balloonPayment && touched.balloonPayment ? 'Please enter a valid amount' : ''}
                      />
                    </Grid>

                    <Grid item md={4} />

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name='financialInformation.pqLow'
                        id='pqLow'
                        required
                        label='Pre-Qualification Low'
                        value={values.financialInformation.pqLow}
                        type='number'
                        helperText={errors.pqLow && touched.pqLow ? errors.pqLow : ''}
                        error={errors.pqLow && touched.pqLow}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name='financialInformation.pqHigh'
                        id='pqHigh'
                        required
                        label='Pre-Qualification High'
                        value={values.financialInformation.pqHigh}
                        type='number'
                        helperText={errors.pqHigh && touched.pqHigh ? errors.pqHigh : ''}
                        error={errors.pqHigh && touched.pqHigh}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name='financialInformation.offerNotes'
                        id='offerNotes'
                        variant='outlined'
                        multiline
                        minRows={4}
                        label='Offer Notes'
                        value={values.financialInformation.offerNotes}
                        helperText={errors.offerNotes && touched.offerNotes ? errors.offerNotes : ''}
                        error={errors.offerNotes && touched.offerNotes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid> */}

                    <Grid container justifyContent="space-between" alignItems="center" xs={12}>
                      <Grid item>
                        <Typography variant="subtitle1">
                          Last Modified By: <span style={{ fontWeight: "bold" }}>{values.lastModified}</span>
                        </Typography>
                      </Grid>
                      
                      <Grid item>
                        <Box display="flex" alignItems="center">
                          <Box mr={3}>
                            <Button
                              onClick={() => {
                                handleReset();
                                history.goBack();
                              }}
                            >
                              Cancel
                            </Button>
                          </Box>
                          <Button type="submit" variant="contained" color="primary">
                            Submit
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>

                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
}
