import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BASE_URI, ROLE_DEALER_ADMIN } from '../../shared/Constants';
import { AuthContext } from '../../shared/context/AuthContext';
import DealerActivatedServiceForm from './DealerActivatedServiceForm';
import DealerAdminForm from './DealerAdminForm';
import DealerImportersForm from './DealerImportersForm';
import DealerMedia from './DealerMedia/DealerMedia';
import PreownedFinance from './DealerPreOwned';
import DealerPrivateNotes from './DealerPrivateNotes';
import DealerSettingForm from './DealerSettingForm';
import DealerSocialMedia from './DealerSocialMedia';
import DealerStaff from './DealerStaff';
import DealerWebsiteAdminForm from './DealerWebsiteAdminForm';
import WebsiteLeads from './WebsiteLeads';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  tabsContainer: {
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    paddingRight: '3rem' 
  },
  scrollButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    background: theme.palette.background.paper,
    borderRadius: '50%',
    boxShadow: theme.shadows[3]
  },
  buttonLeft: {
    left: 0
  },
  buttonRight: {
    right: 0
  }
}));

export default function DealerContainer({ match }) {
  const classes = useStyles();
  let history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [dealer, setDealer] = useState();
  const { id } = useParams();
  const [isAddMode, setIsAddMode] = useState(!id);
  const { role } = useContext(AuthContext);
  const tabsContainerRef = useRef(null);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const hideForRole = () => {
    if (role === ROLE_DEALER_ADMIN) {
      return true;
    }
    return false;
  };

  const tabs = [
    { id: 0, label: 'Dealer Info', comp: <DealerAdminForm history={history} match={match} /> },
    { id: 1, label: 'Dealer Staff', comp: <DealerStaff /> },
    { id: 2, label: 'Website', comp: <DealerWebsiteAdminForm />, hide: hideForRole() },
    { id: 3, label: 'Private Notes', comp: <DealerPrivateNotes /> },
    { id: 4, label: 'Lead Routing', comp: <DealerSettingForm />, hide: hideForRole() },
    { id: 5, label: 'Stock Importer', comp: <DealerImportersForm />, hide: hideForRole() },
    { id: 6, label: 'PRE-OWNED', comp: <PreownedFinance />, hide: hideForRole() },
    { id: 7, label: 'Social Media', comp: <DealerSocialMedia /> },
    { id: 8, label: 'Activated Services', comp: <DealerActivatedServiceForm />, hide: hideForRole() },
    // { id: 8, label: 'Facebook Leads', comp: <DealerFacebookLeads />, hide: hideForSelectionDealerRole() },
    { id: 9, label: 'Website Leads', comp: <WebsiteLeads /> },
    { id: 10, label: 'Media', comp: <DealerMedia />, hide: hideForRole() }
  ];

  const getDealer = async () => {
    try {
      const result = await axios.get(`${BASE_URI}/dealers/${id}`);
      setDealer(result.data);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    getDealer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      {dealer && <h2>{dealer.name}</h2>}
      <AppBar position='static'>
        <div className={classes.tabsContainer} ref={tabsContainerRef}>
          {!isAddMode && (
            <Tabs value={tabIndex} onChange={handleChange} aria-label='dealer tabs' variant='scrollable'>
              {tabs
                .filter((tab) => !tab.hide)
                .map((step, index) => (
                  <Tab key={index} disableRipple label={step.label} />
                ))}
            </Tabs>
          )}
        </div>
      </AppBar>
      <Box mt={4}>
        {tabs
          .filter((tab) => !tab.hide)
          .map((step, index) => (
            <React.Fragment key={index}>{tabIndex === index ? step.comp : null}</React.Fragment>
          ))}
      </Box>
    </div>
  );
}
