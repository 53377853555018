import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { useSnackbar } from 'notistack';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BASE_URI, DEFAULT_PAGE_SIZE } from '../../shared/Constants';
import { DealerContext } from '../../shared/DealerContext';
import ExportContainer from '../../shared/ExportContainer';
import TableHeadersSort from '../../shared/TableHeadersSort';
import { CurrencyValue } from '../../shared/TextMask';
import CategoryFilter from '../categories/CategoryFilter';
import OfferFilter from './OfferFilter';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function OfferTable({ tabStatus }) {
  const classes = useStyles();
  let history = useHistory();
  const { status } = useParams();
  const { dealerArr, dealerId } = useContext(DealerContext);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('CategoryId');
  const [searchString, setSearchString] = useState('');
  const [offerStatus, setOfferStatus] = useState('');
  const [editPriority, setEditPriority] = useState(null);
  const [priorityValues, setPriorityValues] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleEditClick = (row) => {
    setEditPriority(row.id);
    setPriorityValues({ ...priorityValues, [row.id]: row.priority });
  };

  const handlePriorityChange = (event, rowId) => {
    setPriorityValues({ ...priorityValues, [rowId]: event.target.value });
  };
  const { loading, offers, total } = OfferFilter(searchString, offerStatus, page, pageSize, order, orderBy);

  const { categories } = CategoryFilter('', 0, 25, 'desc', 'name', true);

  let offerCats = categories.filter((cat) => cat.type.toLowerCase() === 'offer' && cat.dealerId === dealerId);

  useEffect(() => {
    setOfferStatus(status ? status : tabStatus ? tabStatus : '');
  }, [status, tabStatus]);

  const headCells = [
    { id: 'headline', label: 'Headline', sort: true, width: '50%' },
    { id: 'CategoryId', label: 'Category', sort: true },
    { id: 'leadType', label: 'Type', sort: true },
    { id: 'price', label: 'Price', sort: true, width: '20%' },
    { id: 'variant', label: 'Url', sort: true },
    { id: 'Priority', label: 'Priority', sort: true }
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (event) => {
    setPage(0);
    setSearchString(event.target.value);
  };

  const dealerLookup = (data) => {
    let filter = dealerArr?.filter((d) => d.id == data?.dealerId);
    let url = filter[0]?.websiteUrl;
    var lastChar = url?.substr(url.length - 1);
    if (lastChar !== '/') {
      url = url + '/';
    } else url = url;
    data.url = `${url}special-deals/${data?.model?.replace(/\s/g, '-').replace(/\//g, '-').toLowerCase()}/${data?.ownedModelId}/${
      data?.id
    }`;
  };

  const handleSave = async (row) => {
    let id = row?.id;
    const updatedPriority = priorityValues[id];

    try {
      row.priority = parseInt(updatedPriority);
      row.isEditMode = true;
      await axios.put(`${BASE_URI}/Offers/${id}`, row);
      enqueueSnackbar('Successfully updated offer priority', { variant: 'success' });

    } catch (error) {
      console.error('Error updating priority:', error.response?.data || error.message);
    }

    setEditPriority(null);  
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
            <div style={{ flex: '1 1' }}>
              <ExportContainer values={offers} useDataHeaders fileName='Offers' headers={headCells} />
            </div>

            <TextField
              name='offerFilter'
              id='offerFilter'
              label='Search Offers'
              value={searchString}
              type='text'
              InputProps={{
                endAdornment: searchString ? (
                  <InputAdornment position='end'>
                    <IconButton
                      size='small'
                      disableFocusRipple
                      disableRipple
                      onClick={() => {
                        setSearchString('');
                        setPage(0);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  ''
                )
              }}
              onChange={handleChange}
            />
          </Toolbar>

          <TableContainer>
            <Table aria-label='offers table' size='small'>
              <TableHeadersSort order={order} orderBy={orderBy} headCells={headCells} onRequestSort={handleRequestSort} />
              <TableBody>
                {offers.map((row) => (
                  <Tooltip title={`Edit ${row.active ? 'Active' : 'Inactive'} ${row.headline}`} key={row.id} placement='left'>
                    <TableRow hover>
                      <TableCell className={classes.hoverTableCell} onClick={() => history.push(`/offers/edit/${row.id}`)}>
                        <List dense>
                          <Typography variant='subtitle2' color='textSecondary'>
                            {row.dateFrom ? moment(row.dateFrom).format('l') : ''} - {row.dateTo ? moment(row.dateTo).format('l') : ''}
                          </Typography>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar src={row.imageUrl} />
                            </ListItemAvatar>
                            <ListItemText primary={<strong>{row.headline}</strong>} />
                          </ListItem>
                        </List>
                      </TableCell>
                      <TableCell className={classes.hoverTableCell} onClick={() => history.push(`/offers/edit/${row.id}`)}>
                        <Typography>{offerCats.find((oc) => oc.id === row.categoryId)?.name}</Typography>
                      </TableCell>
                      <TableCell className={classes.hoverTableCell} onClick={() => history.push(`/offers/edit/${row.id}`)}>
                        <Typography>{row.leadType || 'N/A'}</Typography>
                      </TableCell>
                      <TableCell className={classes.hoverTableCell} onClick={() => history.push(`/offers/edit/${row.id}`)}>
                        <Typography>
                          <CurrencyValue value={row.price} />
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.hoverTableCell} onClick={() => history.push(`/offers/edit/${row.id}`)}>
                        {dealerLookup(row)}
                        <Typography>
                          <a href={row?.url} target='_blank' rel='noopener noreferrer'>
                            {row?.url}
                          </a>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {editPriority === row.id ? (
                          <>
                            <TextField
                              fullWidth
                              name='priority'
                              id='priority'
                              label='priority'
                              value={priorityValues[row.id] || ''}
                              onChange={(event) => handlePriorityChange(event, row.id)}
                              type='number'
                              size='small'
                              onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}

                              inputProps={{ min: 0 }}
                            />

                            <Button size='small' onClick={() => handleSave(row)}>
                              Save
                            </Button>
                          </>
                        ) : (
                          <Typography onClick={() => handleEditClick(row)} style={{ cursor: 'pointer', color: 'blue' }}>
                            {row.priority}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page'
                },
                native: true
              }}
              count={total}
              page={page}
              rowsPerPage={pageSize}
              component='div'
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangePageSize}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
