import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TablePagination,
  TextField,
  Tooltip,
  InputLabel,
  Select,
  Checkbox,
  Chip
} from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { BASE_URI } from '../../shared/Constants';
import { DealerContext } from '../../shared/DealerContext';
import ImageUploadInput from '../../shared/ImageUploadFormInput';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  errorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336'
  },
  featureInputContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  td: {
    width: 'fit-content',
    textAlign: 'left'
  },
  th: {
    width: 'fit-content',
    minWidth: '100px',
    textAlign: 'left'
  },
  btn: {
    borderRadius: '5px',
    background: '#3f51b5',
    padding: '6px 30px',
    border: 'none',
    marginRight: '10px',
    color: 'white',
    '&:hover': {
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    }
  },
  PopUp: {
    top: '0',
    zIndex: '555',
    background: '#030303bd',
    height: '100%',
    width: '100%',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(1px)',
    position: 'fixed'
  },
  PopUpCard: {
    width: '37rem',
    height: '37rem',
    background: '#ffffff',
    padding: '40px',
    borderRadius: '5px',
    overflow: 'auto',
    marginTop: '5%'
  },
  PopUpCardDlt: {
    background: '#ffffff',
    padding: '20px',
    width: '30%',
    borderRadius: '5px'
  },
  btnGroup: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'end'
  },
  btnGroupTable: {
    marginTop: '0px',
    display: 'flex',
    justifyContent: 'end'
  },
  AddStaff: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'end'
  },
  bold: {
    fontWeight: 'bolder'
  },
  btnPopUp: {
    background: '#3f51b5',
    padding: '6px 30px',
    border: 'none',
    marginLeft: '10px',
    color: 'white',
    borderRadius: '5px',
    '&:hover': {
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    }
  },
  avatar: {
    width: '60px',
    display: 'flex',
    height: '60px',
    background: (dealerColors) => (dealerColors?.primaryColor ? dealerColors?.primaryColor : '#3f51b5'),
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textTransform: 'uppercase'
  }
}));

export default function DealerStaff({ history, data }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [organizationSubgroups, setOrganizationSubgroups] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [addStaff, setAddStaff] = useState(false);
  const [editStaff, setEditStaff] = useState(false);
  const [dltStaff, setDltStaff] = useState(false);
  const [isPostxUser, setIsPostxUser] = useState(false);
  const [dealerColors, setDealerColors] = useState();
  const [staffList, setStaffList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { dealerOrganizations, organizations } = useContext(DealerContext);
  const [active, setActive] = useState({});
  const [groupdIdToPass, setGroupIdToPass] = useState('');
  const [postxUserList, setPostxUserList] = useState([]);
  const [groupRoles, setGroupRoles] = useState([]);

  const [selectedGroupRole, setSelectedGroupRole] = useState([]);

  const [isMouseOverPopup, setIsMouseOverPopup] = useState(false);

  // Set mouse-over state when hovering over the popup
  const handleMouseEnter = () => setIsMouseOverPopup(true);
  const handleMouseLeave = () => setIsMouseOverPopup(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let staffObj = {
    photoUrl: active ? active?.photoUrl : ' ',
    fullName: active ? active?.fullName : ' ',
    firstname: active ? active?.firstname : ' ',
    surname: active ? active?.surname : ' ',
    email: active ? active?.email : ' ',
    contactNumber: active ? active?.contactNumber : ' ',
    department: active ? active?.department : ' ',
    jobTitle: active ? active?.jobTitle : ' ',
    lastModified: active ? active?.lastModified : ' '
  };

  const classes = useStyles(dealerColors);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerStaff`
    }).then((response) => {
      if (response.data) {
        setStaffList(response.data.filter((s) => s.dealerId == id));
        setLoading(false);
        axios({
          method: 'GET',
          url: `${BASE_URI}/DealerStaff/Department`
        })
          .then((response) => {
            if (response.data) {
              setDepartments(response.data);

              axios({
                method: 'GET',
                url: `${BASE_URI}/DealerStaff/JobTitle`
              }).then((response) => {
                setJobTitles(response.data);
              });
            }
          })
          .catch((err) => {
            enqueueSnackbar('Error', { variant: 'Error' });
          });
      }
    });
  };

  useEffect(() => {
    setStaffList((prevStaffList) =>
      prevStaffList.map((staff) => ({
        ...staff,
        department: departments.find((d) => d.id === staff.departmentId) || null
      }))
    );
  }, [departments, loading]);

  useEffect(() => {
    setStaffList((prevStaffList) =>
      prevStaffList.map((staff) => ({
        ...staff,
        department: departments.find((d) => d.id === staff.departmentId) || null,
        jobTitle: jobTitles.find((d) => d.id === staff.jobTitleId) || null
      }))
    );
  }, [departments, jobTitles, loading]);

  const handleStaffDelete = (e, data) => {
    setActive(data);
    setDltStaff(true);
  };

  const handleStaffAdd = () => {
    setActive({});
    setIsPostxUser(false);
    setAddStaff(true);
  };

  const DltStaff = async (data) => {
    try {
      setLoading(true);
      if (!data.postxUser) {
        await axios.delete(`${BASE_URI}/DealerStaff/Staff/${data.id}`);
      } else {
        await axios.get(`${BASE_URI}/DealerOrganizations/deleteUser`, {
          params: { user_id: data?.id, group_id: groupdIdToPass }
        });
      }
    } catch (err) {
      enqueueSnackbar('Error', { variant: 'error' });
    } finally {
      setStaffList(staffList.filter((s) => s.id !== data.id));
      setLoading(false);
      setLoading(false);
    }
  };

  const getOrganizationSubgroups = async (groupId) => {
    try {
      const result = await axios.get(`${BASE_URI}/DealerOrganizations/subgroups/${groupId}`);
      setOrganizationSubgroups(result?.data ? result?.data?.items : []);
    } catch (error) {
      console.error(error);
    }
  };

  const getGroupRoles = async (groupId) => {
    try {
      const result = await axios.get(`${BASE_URI}/DealerOrganizations/groupRoles/${groupId}`);
      setGroupRoles(result?.data ? result?.data?.items : []);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserGroupRoles = async (userId, groupId) => {
    try {
      const result = await axios.get(
        `${BASE_URI}/DealerOrganizations/getCurrentUserRoles`,

        {
          params: { user_id: userId, group_id: groupId }
        }
      );

      setSelectedGroupRole(result.data.map((d) => d.list_index));
    } catch (error) {
      console.error(error);
    }
  };

  const getOrganizationUsers = async (userId) => {
    try {
      const result = await axios.get(`${BASE_URI}/DealerOrganizations/organization/users/${userId}`);

      let postxUsersList = result.data.items.map((user) => ({
        id: user.id,
        fullName: `${user.firstname} ${user.surname}`,
        firstname: user.firstname,
        surname: user.surname,
        email: user.email,
        contactNumber: user.mobile_phone,
        photoUrl: '',
        dealerId: null,
        departmentId: null,
        jobTitleId: null,
        department: { id: null, name: '' },
        jobTitle: { id: null, name: user.role || '' },
        fileName: null,
        postxUser: true
      }));

      setPostxUserList(postxUsersList);

      setStaffList((prevStaffList) => [...prevStaffList.filter((user) => !user.postxUser), ...postxUsersList]);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (fields) => {
    switch (editStaff) {
      case true:
        if (!isPostxUser) {
          let staffObj = {
            id: active?.id,
            fullName: fields?.fullName,
            email: fields?.email,
            contactNumber: fields?.contactNumber,
            dealerId: fields?.dealerId,
            departmentId: fields?.department?.id,
            jobTitleId: fields?.jobTitle?.id,
            department: fields?.department,
            jobTitle: fields?.jobTitle,
            photoUrl: fields?.photoUrl.image,
            fileName: fields?.photoUrl.fileName
          };

          axios({
            method: 'PUT',
            url: `${BASE_URI}/DealerStaff/UpdateStaff/${active?.id}`,
            data: staffObj
          })
            .then((response) => {
              if (response.data) {
                getData();
                setEditStaff(false);
              }
            })
            .catch((err) => {
              enqueueSnackbar('Error', err, { variant: 'error' });
            });
        } else {
          const Users = {
            email: fields.email,
            firstname: fields.firstname,
            surname: fields.surname,
            mobile_phone: fields.contactNumber
          };

          await axios
            .post(`${BASE_URI}/DealerOrganizations/updateUser/${active?.id}`, Users)
            .then(() => {
              setLoading(false);
              setEditStaff(false);
            })
            .catch((err) => {
              enqueueSnackbar('Error', err, { variant: 'error' });
            });

          if (selectedGroupRole.length > 0) {
            const postxUser = {
              user_id: active?.id,
              group_id: groupdIdToPass,
              roleIds: selectedGroupRole
            };

            await axios
              .post(`${BASE_URI}/DealerOrganizations/createUserRole`, postxUser)
              .then(() => {
                setLoading(false);
                setEditStaff(false);
              })
              .catch((err) => {
                enqueueSnackbar('Error', err, { variant: 'error' });
              });

            postxUser.roleIds = deselectedGroupRole;

            await axios
              .post(`${BASE_URI}/DealerOrganizations/deleteUserRole`, postxUser)
              .then(() => {
                setLoading(false);
                setEditStaff(false);
                enqueueSnackbar('PostX User Updated Successfully!', { variant: 'success' });
              })
              .catch((err) => {
                enqueueSnackbar('Error', err, { variant: 'error' });
              });

              getOrganizationUsers(groupdIdToPass);
          }
        }
        enqueueSnackbar('User Updated Successfully!', { variant: 'success' });

        break;

      case false:
        setLoading(true);
        if (!isPostxUser) {
          fields.dealerId = parseInt(id);
          fields.departmentId = fields.department.id;
          fields.jobTitleId = fields.jobTitle.id;
          //  temporary variables for the properties
          const department = fields.department;
          const jobTitle = fields.jobTitle;
          const photoUrl = fields.photoUrl;
          // Assign the modified properties
          fields.department = department;
          fields.jobTitle = jobTitle;
          fields.photoUrl = photoUrl.image;
          const fileName = photoUrl.fileName;
          fields.fileName = fileName;

          axios({
            method: 'POST',
            url: `${BASE_URI}/DealerStaff/AddStaff`,
            data: fields
          })
            .then((response) => {
              if (response.data) {
                let staffObj = {
                  id: response?.data?.id,
                  photoUrl: response?.data?.photoUrl,
                  fullName: response?.data?.fullName,
                  email: response?.data?.email,
                  contactNumber: response?.data?.contactNumber,
                  dealerId: response?.data?.dealerId,
                  departmentId: response?.data?.departmentId,
                  jobTitleId: response?.data?.jobTitleId,
                  department: fields?.department,
                  jobTitle: fields?.jobTitle
                };

                setStaffList([...staffList, staffObj]);
                enqueueSnackbar('Added' + ' ' + response?.data?.fullName, { variant: 'success' });
                setLoading(false);
                setAddStaff(false);
                setActive(staffObj);
              }
            })
            .catch((err) => {
              enqueueSnackbar('Error', err, { variant: 'error' });
            });
        } else {
          const reqObj = {
            Users: {
              email: fields.email,
              firstname: fields.firstname,
              surname: fields.surname,
              mobile_phone: fields.contactNumber
            },
            UGB: {
              group_id: groupdIdToPass,
              status: 1
            }
          };
          await axios
            .post(`${BASE_URI}/DealerOrganizations/createUser`, reqObj)
            .then(() => {
              setLoading(false);
              setAddStaff(false);
              enqueueSnackbar('PostX User Added Successfully!', { variant: 'success' });
              getOrganizationUsers(groupdIdToPass);
            })
            .catch((err) => {
              enqueueSnackbar('Error', err, { variant: 'error' });
            });
        }

        break;

      default:
        break;
    }
  };

  const onDepartmentChanged = (e, c) => {
    staffObj.department = e?.target?.value;
  };

  const onJobChanged = (e, c) => {
    staffObj.jobTitle = e?.target?.value;
  };

  const [deselectedGroupRole, setDeselectedGroupRole] = useState([]);

  const handleRoleChange = (event) => {
    const value = event.target.value;
    const newlyDeselected = selectedGroupRole.filter((id) => !value.includes(id));
    const newlySelected = value.filter((id) => !selectedGroupRole.includes(id));

    // Update deselectedGroupRole
    setDeselectedGroupRole((prev) => {
      // Remove re-selected items from deselected array
      const updated = prev.filter((id) => !newlySelected.includes(id));
      // Add newly deselected items
      return [...updated, ...newlyDeselected];
    });

    // Update selectedGroupRole
    setSelectedGroupRole(value);
  };

  const handleStaffEdit = (data) => {
    setActive(data);
    setLoading(false);
    setEditStaff(true);
    staffObj.fullName = data.fullName;
    if (data?.postxUser) {
      setIsPostxUser(data.postxUser);
      let postXuser = postxUserList.find((d) => d.id == data.id);
      staffObj.firstname = postXuser.firstname;
      staffObj.surname = postXuser.surname;
      staffObj.mobile_phone = postXuser.mobile_phone;
      getGroupRoles(groupdIdToPass).then(() => {
        getUserGroupRoles(data?.id, groupdIdToPass);
      });
    } else {
      setIsPostxUser(false);
    }
  };

  const handlePopUpResponse = (answer) => {
    if (answer === 'Yes') {
      DltStaff(active).then(() => {
        enqueueSnackbar(`Deleted ${active.fullName}`, { variant: 'success' });
      });
    }
    setDltStaff(false);
  };

  const handleClose = (e) => {
    setEditStaff(false);
    setAddStaff(false);
    setDltStaff(false);
  };

  const initialValues = {
    selectedOrg: '',
    selectedSubgroup: ''
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Only apply the click outside logic if the mouse is NOT over the popup
      if (!isMouseOverPopup && !e.target.closest(`.${classes.PopUpCard}`)) {
        setEditStaff(false);
        setAddStaff(false);
      }
    };

    // Add the event listener for clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on unmount
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMouseOverPopup, classes.PopUpCard]);

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <Grid item xs={12} md={12}>
            <Formik initialValues={initialValues}>
              {({ values, setFieldValue, errors, touched }) => (
                <Box mb={2} mt={2}>
                  <Form style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
                    {/* Organization Dropdown */}
                    <FormControl
                      variant='outlined'
                      size='small'
                      style={{ minWidth: 350, marginRight: 'auto' }}
                      error={touched.selectedOrg && !!errors.selectedOrg}
                    >
                      <InputLabel>Select Organization</InputLabel>
                      <Tooltip title='Please make sure to link organization to this dealer'>
                        <Select
                          name='selectedOrg'
                          value={values.selectedOrg}
                          onChange={(e) => {
                            const selectedOrgId = e.target.value;
                            setFieldValue('selectedOrg', selectedOrgId);
                            getOrganizationUsers(selectedOrgId);
                            getOrganizationSubgroups(selectedOrgId);
                            setGroupIdToPass(selectedOrgId);
                          }}
                          label='Select Organization'
                        >
                          {dealerOrganizations.map((org) => (
                            <MenuItem key={org.organizationId} value={org.organizationId}>
                              {organizations.find((o) => o.id === org.organizationId)?.name || 'Unknown'}
                            </MenuItem>
                          ))}
                        </Select>
                      </Tooltip>
                    </FormControl>

                    {/* Subgroup Dropdown */}
                    <FormControl
                      variant='outlined'
                      size='small'
                      style={{ minWidth: 350, marginRight: 'auto' }}
                      error={touched.selectedSubgroup && !!errors.selectedSubgroup}
                    >
                      <InputLabel>Select Sub Group</InputLabel>
                      <Select
                        name='selectedSubgroup'
                        value={values.selectedSubgroup}
                        onChange={(e) => {
                          const selectedSubGroupId = e.target.value;
                          setFieldValue('selectedSubgroup', selectedSubGroupId);
                          getOrganizationUsers(selectedSubGroupId);
                          setGroupIdToPass(selectedSubGroupId);
                        }}
                        label='Select Subgroup'
                        disabled={!values.selectedOrg}
                      >
                        {organizationSubgroups.map((sub) => (
                          <MenuItem key={sub.id} value={sub.id}>
                            {sub.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Submit Button */}
                    <Button className='btn' variant='contained' color='primary' onClick={handleStaffAdd}>
                      Add Staff +
                    </Button>
                  </Form>
                </Box>
              )}
            </Formik>

            {dltStaff && (
              <div className={classes.PopUp}>
                <div className={classes.PopUpCardDlt}>
                  <Typography variant='body1' gutterBottom>
                    Are you sure you want to delete <span className={classes.bold}>{active.fullName}</span> ?
                  </Typography>

                  <div className={classes.btnGroup}>
                    <Button className={classes.btnPopUp} variant='contained' color='primary' onClick={() => handlePopUpResponse('Yes')}>
                      Yes
                    </Button>
                    <Button
                      className={classes.btnPopUp}
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        handlePopUpResponse('No');
                      }}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {(addStaff || editStaff) && (
              <div className={classes.PopUp}>
                <div className={classes.PopUpCard} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <Typography variant='body1' gutterBottom>
                    {addStaff ? 'Add Staff' : 'Edit Staff'}
                  </Typography>

                  <Formik
                    initialValues={staffObj}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                      onSubmit(values);
                      setTimeout(() => {
                        actions.setSubmitting(false);
                      }, 500);
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                                    .test('valid-emails', 'One or more emails are invalid', (value) => {
                                      if (!value) return true;
                                      const emails = value.split(',').map((email) => email.trim());
                                      return emails.every((email) => Yup.string().email().isValidSync(email));
                                    })
                                    .required('Email is required'),
                      fullName: !isPostxUser ?  Yup.string().required('Full Name is required') : null,
                      firstname: isPostxUser ?  Yup.string().required('First Name is required') : null,
                      surname: isPostxUser ?  Yup.string().required('Surname is required') : null,
                    })}
                  >
                    {(props) => {
                      const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

                      let sortedDepartments = departments.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
                      let sortedJobTitles = jobTitles.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));

                      return (
                        <Form>
                          <Box mt={2} className={classes.AddDealer}>
                            {!isPostxUser && <ImageUploadInput title='Photo' value={staffObj?.photoUrl} fieldName={'photoUrl'} />}
                          </Box>
                          <Box mt={2} className={classes.AddDealer}>
                            <Grid item xs={12} md={6}>
                              <FormControlLabel
                                id='isPostxUser'
                                name='isPostxUser'
                                checked={isPostxUser}
                                control={<Checkbox name={isPostxUser} color='primary' onChange={() => setIsPostxUser((prev) => !prev)} />}
                                label={'PostX User?'}
                              />
                            </Grid>

                            {!isPostxUser && (
                              <TextField
                                fullWidth
                                name='fullName'
                                required
                                id='fullName'
                                label='Full Name'
                                value={values.fullName}
                                helperText={errors.fullName && touched.fullName ? errors.fullName : ''}
                                error={errors.fullName && touched.fullName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            )}

                            {isPostxUser && (
                              <>
                              <Box mt={2} >
                                <TextField
                                  fullWidth
                                  name='firstname'
                                  required
                                  id='firstname'
                                  label='First Name'
                                  value={values.firstname}
                                  helperText={errors.firstname && touched.firstname ? errors.firstname : ''}
                                  error={errors.firstname && touched.firstname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                </Box>
                                <Box mt={2} >
                                <TextField
                                  fullWidth
                                  name='surname'
                                  required
                                  id='surname'
                                  label='Surname'
                                  value={values.surname}
                                  helperText={errors.surname && touched.surname ? errors.surname : ''}
                                  error={errors.surname && touched.surname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                </Box>
                                {editStaff && (
                                  <>
                                  <Box mt={2} >
                                    <FormControl fullWidth>
                                      <InputLabel>Select Group Role</InputLabel>
                                      <Select
                                        multiple
                                        name='selectedGroupRole'
                                        value={selectedGroupRole}
                                        onChange={(e) => {
                                          handleRoleChange(e);
                                        }}
                                        renderValue={(selected) => (
                                          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                                            {selected.map((id) => {
                                              const selectedRole = groupRoles.find((role) => role.list_index == id);
                                              return <Chip key={id} label={selectedRole?.name || 'Unknown'} />;
                                            })}
                                          </div>
                                        )}
                                      >
                                        {groupRoles.map((groupRole) => (
                                          <MenuItem key={groupRole.list_index} value={groupRole.list_index}>
                                            <Checkbox checked={selectedGroupRole.includes(groupRole.list_index)} />
                                            {groupRole?.name || 'Unknown'}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    </Box>
                                  </>
                                )}
                              </>
                            )}
                          </Box>
                          <Box mt={2} >
                            <TextField
                              name='email'
                              id='email'
                              label='Emails'
                              required
                              value={values.email}
                              error={Boolean(errors.email && touched.email)}
                              helperText={errors.email && touched.email ? errors.email : 'dealer staff comma-separated emails'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                            />

                            </Box>
                           
                            <Box mt={2} >
                            <TextField
                              fullWidth
                              name='contactNumber'
                              id='contactNumber'
                              label='Contact Number'
                              value={values.contactNumber}
                              type='text'
                              helperText={errors.contactNumber && touched.contactNumber ? errors.contactNumber : ''}
                              error={errors.contactNumber && touched.contactNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            </Box>
                           
                          {!isPostxUser && (
                            <>
                               <Box mt={2} >
                                <TextField
                                  fullWidth
                                  name='department'
                                  select
                                  id='department'
                                  label='Select A Department'
                                  value={staffObj.department}
                                  type='text'
                                  helperText={errors.department && touched.department ? errors.department : ''}
                                  error={errors.department && touched.department}
                                  onChange={(event, child) => {
                                    onDepartmentChanged(event, child);
                                    handleChange(event);
                                  }}
                                  onBlur={handleBlur}
                                >
                                  {sortedDepartments.map((department) => (
                                    <MenuItem value={department}>{department.name}</MenuItem>
                                  ))}
                                </TextField>
                                </Box>

                                <Box mt={2} >
                                <TextField
                                  fullWidth
                                  name='jobTitle'
                                  select
                                  id='jobTitle'
                                  label='Select A Job Title'
                                  value={staffObj.jobTitle}
                                  type='text'
                                  helperText={errors.jobTitle && touched.jobTitle ? errors.jobTitle : ''}
                                  error={errors.jobTitle && touched.jobTitle}
                                  onChange={(event, child) => {
                                    onJobChanged(event, child);
                                    handleChange(event);
                                  }}
                                  onBlur={handleBlur}
                                >
                                  {sortedJobTitles.map((job) => (
                                    <MenuItem value={job}>{job.name}</MenuItem>
                                  ))}
                                </TextField>
                              </Box>
                            </>
                          )}

                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <Typography variant="subtitle1">
                              Last Modified By: <span style={{ fontWeight: "bold" }}>{staffObj.lastModified}</span>
                            </Typography>
                          </Grid>

                          <Grid item>
                            <div className={classes.btnGroup}>
                              {!loading ? (
                                <Button className={classes.btn} variant="contained" color="primary" type="submit" value="Add">
                                  {addStaff ? "Add" : "Update"}
                                </Button>
                              ) : (
                                <div style={{ marginRight: "20px" }}>
                                  <TailSpin ariaLabel="loading-indicator" color="#3f51b5" height="40px" width="40px" />
                                </div>
                              )}

                              <Button className={classes.btn} variant="contained" color="primary" onClick={handleClose} value="Cancel">
                                Cancel
                              </Button>
                            </div>
                          </Grid>
                        </Grid>

                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            )}
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TailSpin ariaLabel='loading-indicator' color='#3f51b5' />
              </div>
            )}
            {!loading && staffList.length > 0 && (
              <div style={{ overflow: 'auto' }}>
                <>
                  <table border='1' cellPadding='5px' width='100%'>
                    <thead>
                      <tr>
                        <th>Photo</th>
                        <th>Name & Surname</th>
                        <th>Email</th>
                        <th>Department</th>
                        <th>Job Title</th>
                        <th>PostX User</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {staffList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((staff) => (
                        <tr key={staff.id}>
                          <td>
                            {staff.photoUrl ? (
                              <img src={staff.photoUrl} width='60px' height='60px' alt='Staff' />
                            ) : (
                              <div
                                style={{
                                  width: '60px',
                                  height: '60px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: '#ccc',
                                  borderRadius: '50%',
                                  fontSize: '20px',
                                  fontWeight: 'bold',
                                  color: '#fff'
                                }}
                              >
                                {staff.fullName.split(' ')[0][0]}
                                {staff.fullName.split(' ')[1]?.[0] || ''}
                              </div>
                            )}
                          </td>
                          <td>{staff.fullName}</td>
                          <td>{staff.email}</td>
                          <td>{staff.department?.name}</td>
                          <td>
                            <ul>
                              {staff.jobTitle?.name
                                ?.split(',')
                                .map((title, index) => (title.trim() !== '' ? <li key={index}>{title.trim()}</li> : null))}
                            </ul>
                          </td>
                          <td>{staff.postxUser ? 'YES' : 'NO'}</td>
                          <td>
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <Button variant='contained' color='primary' onClick={() => handleStaffEdit(staff)}>
                                Edit
                              </Button>
                              <Button variant='contained' color='secondary' onClick={(e) => handleStaffDelete(e, staff)}>
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component='div'
                    count={staffList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
