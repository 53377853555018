import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BASE_URI, ROLE_ADMIN } from './Constants';
import { AuthContext } from './context/AuthContext';

export const DealerContext = createContext();

export const DealerContextProvider = (props) => {
  const [selectedMotorGroup, setSelectedMotorGroup] = useState({});
  const [motorGroupId, setMotorGroupId] = useState('');
  const [isMasterDealer, setIsMasterDealer] = useState(false);
  const [dealerId, setDealerId] = useState('');
  const [dealerName, setDealerName] = useState('');
  const { userDealerIDs, role, isAuthenticated } = useContext(AuthContext);
  const [dealerArr, setDealerArr] = useState([]);

  const [organizations, setOrganizations] = useState([]);
  const [dealerOrganizations, setDealerOrganizations] = useState([]);
  let location = useLocation();

  const hasRole = (roleToCheck) => {
    if (!role) {
      return false;
    }

    return role === roleToCheck;
  };

  const filterDealersForUser = (dealers) => {
    if (!dealers || dealers.length === 0 || hasRole(ROLE_ADMIN)) return;

    var dealerArr = dealers.filter((d) => userDealerIDs.includes(d.id));
    setDealerArr(dealerArr);
  };

  useEffect(() => {
    const getDealers = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/dealers`, {
          params: { pageNumber: -1 }
        });
        setDealerArr(result?.data.list);
      } catch (error) {
        if (axios.isCancel(error)) return;
        //enqueueSnackbar("Unable to load dealerships", {variant: 'error'});
      }
    };

    getDealers().then((dealers) => {
      var thisDealer = findDealer(userDealerIDs && userDealerIDs.length > 0 ? userDealerIDs[0] : 0, dealers);
      getOrganizations();
      filterDealersForUser(dealers);
    });

     
      const path = location?.pathname;
      if (path.includes(ROLE_ADMIN.toLowerCase()) && (path.includes('dealers/add') || path.includes('dealers/edit'))) {
        const dealerId = path.split('/').filter(Boolean).pop();
        if (dealerId && isAuthenticated) {
          getDealerOrganizations(dealerId);
        }
      }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDealerIDs, selectedMotorGroup?.id]);

  const updateSelectedMotorGroup = (motorGroup, dealerId) => {
    setDealerOrganizations([]);
    setSelectedMotorGroup(motorGroup);
    setDealerId(dealerId);
    setIsMasterDealer(getIsMasterDealer(motorGroup));
    getDealerOrganizations(motorGroup?.id);
  };

  const findDealer = (id, dealers) => {
    if (!id || !Array.isArray(dealers) || dealers.length === 0) return;

    const dealer = dealers.find((d) => d.id === id);
    if (dealer) {
      setSelectedMotorGroup(dealer);
      setDealerId(dealer?.id);
      setDealerName(dealer?.name);
    }
    return dealer;
  };

  const updateMotorGroupId = (id) => {
    setMotorGroupId(id);
  };

  const getIsMasterDealer = (dealer = selectedMotorGroup) => {
    // A dealer is a master when its name contains the word 'master'
    return dealer?.name?.toLowerCase().includes('master');
  };

  const getOrganizations = async () => {
    try {
      const result = await axios.get(`${BASE_URI}/DealerOrganizations/organizations`);
      if (result.data.length !== 0) {
        setOrganizations(result.data.items);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDealerOrganizations = async (id) => {
    try {
      const result = await axios.get(`${BASE_URI}/DealerOrganizations/organization/${id}`);
      if (result.data.length !== 0) {
        setDealerOrganizations(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <DealerContext.Provider
      value={{
        dealerArr,
        setDealerArr,
        selectedMotorGroup,
        motorGroupId,
        dealerId,
        dealerName,
        isMasterDealer,
        updateMotorGroupId,
        updateSelectedMotorGroup,
        organizations,
        dealerOrganizations
      }}
    >
      {props.children}
    </DealerContext.Provider>
  );
};

export default DealerContextProvider;
