import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useParams } from "react-router-dom";
import WebsiteSocialMedia from "./WebsiteSocialMedia";
import WebsiteInfo from "./WebsiteInfo";
import WebsiteSeo from "./WebsiteSeo";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#3378af",
    },
    contentContainer: {
        padding: theme.spacing(2),
    },
}));

const tabsConfig = [
    { label: "Info", component: <WebsiteInfo /> },
    { label: "Social", component: <WebsiteSocialMedia /> },
    { label: "S.E.O", component: <WebsiteSeo /> },
];

export default function WebsiteContainer() {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const { id } = useParams();
    const [isAddMode, setIsAddMode] = useState(!id);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <AppBar position="relative" className={classes.root}>

                {!isAddMode && 
                
                <Tabs
                    variant="scrollable"
                    value={selectedTab}
                    onChange={handleChange}
                    textColor="inherit"
                >
                    {tabsConfig.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                    ))}
                </Tabs>
                
                }
                
            </AppBar>
            <div className={classes.contentContainer}>
                {tabsConfig[selectedTab]?.component}
            </div>
        </>
    );
}
