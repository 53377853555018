import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
  CircularProgress,
  Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Switch } from 'formik-material-ui';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { BASE_URI } from '../../shared/Constants';
import { LoadingContext } from '../../shared/context/loadingContext';
import { DealerContext } from '../../shared/DealerContext';
import ImageUploadInput from '../../shared/ImageUploadFormInput';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  sectionContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2)
  },
  formButtons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonStyles: {
    marginRight: '1rem',
    textTransform: 'none',
    borderRadius: '5px',
    border: '1px solid #012F56',
    background: '#012F56'
  },
  textArea: {
    fontFamily: 'monospace',
    padding: '10px',
    width: '100%',
    height: '200px !important',
    fontSize: '12px',
    overflowY: 'scroll',
    whiteSpace: 'pre-wrap',
    backgroundColor: '#f4f4f4',
    border: '1px solid #ccc',
    borderRadius: '5px'
  }
}));

const validationSchema = Yup.object().shape({
  headline: Yup.string().required('Headline is required'),
  date: Yup.date().required('Date is required'),
  banner: Yup.object().shape({
    imageUrl: Yup.string().required('Banner image URL is required'),
    altTag: Yup.string().required('Alt tag is required')
  }),
  blogSections: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Section title is required'),
      description: Yup.string().required('Section description is required'),
      image: Yup.object().shape({
        imageUrl: Yup.string().required('Section image URL is required'),
        altTag: Yup.string().required('Image alt tag is required')
      })
    })
  )
});

export default function BlogForm() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);
  const { dealerId, selectedMotorGroup: selectedDealer } = useContext(DealerContext);
  const [isLoadingDealers, setIsLoadingDealers] = useState(true);
  const [relatedDealerIDs, setRelatedDealerIDs] = useState([]);
  const [relatedDealers, setRelatedDealers] = useState([]);
  const [motorGroupDealers, setMotorGroupDealers] = useState([]);

  const [isAddMode, setIsAddMode] = useState(!id);
  const initialValues = {
    headline: '',
    date: new Date().toISOString().split('T')[0],
    blogSections: [],
    banner: {
      imageUrl: '',
      altTag: ''
    },
    active: false
  };

  const [formFields, setFormFields] = useState(initialValues);

  useEffect(() => {
    if (id) {
      const fetchBlog = async () => {
        try {
          showLoading();
          const { data } = await axios.get(`${BASE_URI}/Blog/${id}`);
          setFormFields(data);
          setRelatedDealerIDs(data?.relatedDealerIDs);
        } catch (error) {
          enqueueSnackbar('Unable to fetch blog details', { variant: 'error' });
        } finally {
          hideLoading();
        }
      };
      fetchBlog();
    }
  }, [id]);

  useEffect(() => {
    if (dealerId > 0 && selectedDealer?.motorgroupID >= 0) {
      getMotorGroupDealers();
    }
  }, [dealerId, selectedDealer]);

  useEffect(() => {
      syncRelatedDealers(motorGroupDealers, relatedDealerIDs);
    }, [motorGroupDealers, relatedDealerIDs]);

  const handleSubmit = async (values, { setSubmitting, resetForm } ) => {
    try {
      showLoading();
      // Handle banner image properly
      if (!dealerId) {
        enqueueSnackbar('Dealer must be selected first', { variant: 'warning' });
        hideLoading();
        setSubmitting(false);
        return;
      }

      values.dealerId = dealerId;

      const banner = values.banner?.imageUrl;
      values.banner.imageUrl = banner.image || banner;
      values.banner.altTag = values.altTag || values.banner.altTag;

      for (let i = 0; i < values.blogSections?.length; i++) {
        const blogSection = values.blogSections[i]?.image.imageUrl;
        values.blogSections[i].image.imageUrl = blogSection?.image || blogSection;
        values.blogSections[i].blogId = id;
      }
      if (isAddMode) {
        let result = await axios.post(`${BASE_URI}/Blog`, values);
        enqueueSnackbar('Blog created successfully', { variant: 'success' });
        saveToRelatedDealers(result?.data, setSubmitting, resetForm);
      } else {
        let result = await axios.put(`${BASE_URI}/Blog/${id}`, values);
        enqueueSnackbar('Blog updated successfully', { variant: 'success' });
        saveToRelatedDealers(result?.data, setSubmitting, resetForm);
      }

      history.push('/blogs');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to save the blog', { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  async function saveToRelatedDealers(blog, setSubmitting, resetForm) {
    try {
      blog.relatedDealerIDs = relatedDealerIDs;
      const result = await axios.post(`${BASE_URI}/Blog/UpdateRelatedDealers/${blog.id}`, blog);
      enqueueSnackbar('blog saved to linked dealers', { variant: 'success' });
      resetForm();
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Failed to save the blog to the linked dealers', { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  }

  function toggleRelatedDealer(dealerId) {
    // This round-about way is to ensure that the checkbox is rendered after its value is updated
    let list = [...relatedDealers];
    let dealer = list.find((d) => d.dealerId == dealerId);
    dealer.isTicked = !dealer.isTicked;
    setRelatedDealers(list);
    setRelatedDealerIDs(list.filter((d) => d.isTicked).map((d) => d.dealerId));
  }

  async function getMotorGroupDealers() {
    if (!selectedDealer?.motorgroupID) {
      setIsLoadingDealers(false);
      return motorGroupDealers;
    }

    const params = {
      motorgroupId: selectedDealer.motorgroupID,
      pageSize: 500 // Fetch all dealers as there's no paging management on the UI
    };

    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URI}/Dealers`,
        params
      });

      let dealers = response?.data?.list?.filter((d) => d.id !== dealerId);

      if (!dealers || dealers.length <= 0) {
        enqueueSnackbar('No motorgroup dealers available for the current dealer', { variant: 'warning' });
        setIsLoadingDealers(false);
        return;
      }

      setMotorGroupDealers(dealers);
      return dealers;
    } catch (error) {
      if (!axios.isCancel(error)) {
        enqueueSnackbar("Unable to load the motorgroup's dealers", { variant: 'error' });
      }
      return [];
    } finally {
      setIsLoadingDealers(false);
    }
  }

  function syncRelatedDealers(dealers, dealerIDs) {
    let tempRelatedDealers = [];

    for (let d = 0; d < dealers?.length; d++) {
      const dealer = dealers[d];

      if (dealer.id == dealerId) continue;

      if (dealerIDs?.some((id) => id == dealer.id)) {
        // This dealer corresponds to a related dealer, tick it
        tempRelatedDealers.push({
          dealerId: dealer.id,
          name: dealer.name.trim(),
          isTicked: true
        });
      } else {
        // This dealer does not correspond to a related dealer, untick it
        tempRelatedDealers.push({
          dealerId: dealer.id,
          name: dealer.name.trim(),
          isTicked: false
        });
      }
    }

    setRelatedDealers([...tempRelatedDealers]);
  }

  return (
    <Paper className={classes.paper}>
      <Formik initialValues={formFields} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, resetForm }) => {
          const { date } = values;
          values.date = date ? date.split('T')[0] : new Date().toISOString().split('T')[0];

          return (
            <Form>
              <Toolbar disableGutters>
                <Typography variant='h5' style={{ flex: '1 1', fontWeight: 600 }} gutterBottom>
                  {isAddMode ? 'Add' : 'Edit'} Blog
                </Typography>
                <FormControlLabel
                  control={<Field component={Switch} color='primary' type='checkbox' name='active' id='active' />}
                  label='Active'
                />
              </Toolbar>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      name='headline'
                      label='Article Headline'
                      value={values.headline}
                      variant='outlined'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.headline && !!errors.headline}
                      helperText={touched.headline && errors.headline}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      name='date'
                      label='Article Date'
                      variant='outlined'
                      type='date'
                      InputLabelProps={{ shrink: true }}
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.date && !!errors.date}
                      helperText={touched.date && errors.date}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ImageUploadInput
                      title='Banner Image'
                      fieldName='banner.imageUrl'
                      error={touched.banner?.imageUrl && !!errors.banner?.imageUrl}
                    />
                    <Box mt={2}>
                      <TextField
                        fullWidth
                        name='banner.altTag'
                        label='Banner Alt Tag'
                        value={values.banner.altTag}
                        variant='outlined'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.banner?.altTag && !!errors.banner?.altTag}
                        helperText={touched.banner?.altTag && errors.banner?.altTag}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6} style={{ height: '20rem', overflowY: 'scroll' }}>
                    <Typography variant='body' gutterBottom>
                      Select the dealers to link to this offer
                    </Typography>
                    <table width='100%' border='1'>
                      <thead>
                        <tr className={classes.tr}>
                          <th className={classes.th}>Dealer Name</th>
                          <th className={classes.th}>Linked</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(isLoadingDealers || isLoading) && (
                          <tr>
                            <td colSpan={2} className={classes.tdCenter}>
                              <CircularProgress />
                            </td>
                          </tr>
                        )}
                        {!isLoadingDealers && !isLoading && !(relatedDealers?.length > 0) && (
                          <tr>
                            <td colSpan={2} className={classes.tdCenter}>
                              <Typography variant='caption'>No other dealers in this motorgroup</Typography>
                            </td>
                          </tr>
                        )}
                        {!isLoadingDealers &&
                          !isLoading &&
                          relatedDealers?.length > 0 &&
                          relatedDealers
                            .sort((a, b) => a?.name.localeCompare(b?.name))
                            .map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className={classes.td}>
                                    <div key={`${index}-1`}>{item.name}</div>
                                  </td>
                                  <td className={classes.tdCenter}>
                                    <div key={`${index}-2`}>
                                      <Checkbox
                                        name={item.name}
                                        color='primary'
                                        checked={item.isTicked}
                                        disabled={isSubmitting}
                                        onChange={() => {
                                          toggleRelatedDealer(item.dealerId);
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {!isAddMode && (
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Typography variant='h6'>Blog Sections</Typography>
                      </Box>

                      <FieldArray name='blogSections'>
                        {({ remove, push }) => (
                          <>
                            {values.blogSections?.map((section, index) => (
                              <div key={index} className={classes.sectionContainer}>
                                <Typography variant='subtitle1'>Section {index + 1}</Typography>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={6}>
                                    <ImageUploadInput title='Section Image' fieldName={`blogSections[${index}].image.imageUrl`} />
                                    <Box mt={2}>
                                      <TextField
                                        fullWidth
                                        name={`blogSections[${index}].image.altTag`}
                                        label='Image Alt Tag'
                                        value={section.image?.altTag}
                                        variant='outlined'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          touched.blogSections?.[index]?.image?.altTag && !!errors.blogSections?.[index]?.image?.altTag
                                        }
                                        helperText={
                                          touched.blogSections?.[index]?.image?.altTag && errors.blogSections?.[index]?.image?.altTag
                                        }
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={6} style={{ marginTop: '22px' }}>
                                    <TextField
                                      fullWidth
                                      name={`blogSections[${index}].title`}
                                      label='Section Title'
                                      value={section.title}
                                      variant='outlined'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={touched.blogSections?.[index]?.title && !!errors.blogSections?.[index]?.title}
                                      helperText={touched.blogSections?.[index]?.title && errors.blogSections?.[index]?.title}
                                    />
                                    <InputLabel htmlFor='description'>Description</InputLabel>
                                    <TextareaAutosize
                                      name={`blogSections[${index}].description`}
                                      label='Section Description'
                                      multiline
                                      variant='outlined'
                                      value={section.description}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={touched.blogSections?.[index]?.description && !!errors.blogSections?.[index]?.description}
                                      helperText={touched.blogSections?.[index]?.description && errors.blogSections?.[index]?.description}
                                      className={classes.textArea}
                                      minRows={6}
                                    />
                                  </Grid>
                                </Grid>
                                <Box mt={2} textAlign='right'>
                                  <Button
                                    className={classes.buttonStyles}
                                    style={{ background: 'red' }}
                                    variant='contained'
                                    size='small'
                                    onClick={() => remove(index)}
                                  >
                                    <RemoveIcon /> Remove Section
                                  </Button>
                                </Box>
                              </div>
                            ))}
                            <Button
                              variant='contained'
                              color='primary'
                              size='small'
                              className={classes.buttonStyles}
                              onClick={() => push({ title: '', description: '', image: { imageUrl: '', altTag: '' } })}
                            >
                              <AddIcon /> Add Section
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  )}

                  <Grid item xs={12} className={classes.formButtons}>
                    <Button onClick={() => history.push('/blogs')}>Cancel</Button>
                    <Button className={classes.buttonStyles} type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                      Publish
                    </Button>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}
